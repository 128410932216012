._1xqHH59xabOgiZbtftEmjx .vQvg1QUtn44lq9LGyYC2j {
  padding: 0 !important; }

._1xqHH59xabOgiZbtftEmjx ._3XIb-b8AkzO1l-Zx0ltC3L {
  margin: 10px;
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 3px 0px; }
  ._1xqHH59xabOgiZbtftEmjx ._3XIb-b8AkzO1l-Zx0ltC3L img {
    width: 100%;
    height: auto; }

._1xqHH59xabOgiZbtftEmjx ._1HZgh0ykcjSz7BVFo09Xf7 {
  text-transform: uppercase; }

._1xqHH59xabOgiZbtftEmjx ._2Nz0OwE7gguHtFnb2D2z8e {
  padding: 20px; }

._1xqHH59xabOgiZbtftEmjx .F9wV_qnjccra_sgtnlCjC {
  border-bottom: 1px solid #eee; }

._1xqHH59xabOgiZbtftEmjx ._3PvVpFqmeSMxHpRXk5761C {
  width: 100%;
  min-height: 200px;
  background-size: cover; }

._1xqHH59xabOgiZbtftEmjx ._1SG8UCwOZ6mJeJHZZsRJMw {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px; }

._1xqHH59xabOgiZbtftEmjx ._1eFbDCRWTNLNtAM_iB2GGe {
  background-size: cover;
  height: 250px;
  background-repeat: no-repeat; }

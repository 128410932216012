._136QKDINAR5okEZ9Ak3ZEe {
  font-size: 0.9rem;
  line-height: 20px; }
  @media (min-width: 768px) {
    ._136QKDINAR5okEZ9Ak3ZEe.h6nyX8GCfngWgwGYmhNVA {
      border-right: solid 1px #767676; } }
  ._136QKDINAR5okEZ9Ak3ZEe ._1xbcTSuts7Dkp64FRs5iJr {
    cursor: pointer; }
    ._136QKDINAR5okEZ9Ak3ZEe ._1xbcTSuts7Dkp64FRs5iJr ._30wbDheCZ2e23zW3P2HZM_ {
      display: inline-block;
      vertical-align: top;
      margin-right: 1rem; }
    @media (max-width: 768px) {
      ._136QKDINAR5okEZ9Ak3ZEe ._1xbcTSuts7Dkp64FRs5iJr ._2VX2PeqGeWtyPr9SYBuuLt {
        display: inline-block;
        vertical-align: top;
        margin-right: 1rem; } }
  ._136QKDINAR5okEZ9Ak3ZEe ._3ykiTWeselVpg4Hsi4KgoJ {
    padding-top: 0.4rem;
    padding-bottom: 1rem; }
    ._136QKDINAR5okEZ9Ak3ZEe ._3ykiTWeselVpg4Hsi4KgoJ ._3O9jA68CBBBC5ikMXQBjgy {
      display: flex;
      justify-content: space-between; }
  ._136QKDINAR5okEZ9Ak3ZEe .DFCW_5CSEZ1nOBeTlciXB {
    font-weight: bold;
    margin-left: 1rem; }
  ._136QKDINAR5okEZ9Ak3ZEe ._2F3QsmhvC57cLUTpqTo3wx {
    padding-top: 0.5rem; }
    ._136QKDINAR5okEZ9Ak3ZEe ._2F3QsmhvC57cLUTpqTo3wx ._89fAeSWkIRzP-ToaZhNIp {
      font-weight: bold;
      background-color: #007ea8;
      color: white;
      padding: 0.4rem 0.7rem;
      font-size: 0.7rem;
      border: none;
      cursor: pointer; }

._1BvIl3MeuZG7uPYWPoznip {
  color: #337ca3;
  cursor: pointer;
  text-decoration: underline; }

._2Jwhxv3c9SNbdeN6rtCOUs {
  height: 520px;
  overflow-y: scroll; }

._2hhDzFWIItcd5UP1ar7oGg {
  padding-top: 0.4rem; }

._3QxUyrpoJOnoWLh1yh9xe6 {
  background: white;
  border: solid 1px #cccccc;
  display: none;
  position: absolute;
  z-index: 200;
  top: 50px;
  width: 300px;
  max-height: 100vh;
  padding: 20px 10px; }
  @media (max-width: 1300px) {
    ._3QxUyrpoJOnoWLh1yh9xe6.Kv848xmiRgas8D0pwPX8R {
      right: 10px; } }

._2c_D0OhqKdL0G5lR4Wq-mT {
  display: block; }

.aIKhihDCvlHFsNyMiixvk {
  min-width: 6rem;
  display: inline-block; }

.bJ7P4A1vcgn0w-ts0ElUs {
  position: absolute;
  top: 15px;
  right: 10px;
  color: #636363;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer; }

._3hfyQWig9Nl7oM0a8Rp25S ._5mVskOlb2JKy_CIiU3s8O {
  margin: 0 auto 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 15px; }

._3hfyQWig9Nl7oM0a8Rp25S ._13GSbxC3kpt1Oi7r0RJtg_ {
  padding: 0 !important; }

._3hfyQWig9Nl7oM0a8Rp25S ._3YE1r9GNv9mff3S6rl9IJO {
  margin: 10px;
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 3px 0px; }
  ._3hfyQWig9Nl7oM0a8Rp25S ._3YE1r9GNv9mff3S6rl9IJO img {
    width: 100%;
    height: auto; }

._3hfyQWig9Nl7oM0a8Rp25S ._1GrSNCE68gTJZdR3n7qs6b {
  text-transform: uppercase; }

._3hfyQWig9Nl7oM0a8Rp25S ._1NYVYgomGzZtD2KdYR3mMa {
  padding: 20px; }

._3hfyQWig9Nl7oM0a8Rp25S ._2XLJ_GfHfKvZtsEhTAtaSZ {
  border-bottom: 1px solid #eee; }

._3hfyQWig9Nl7oM0a8Rp25S ._1MOaXeLAHUOTyVUdZIfRuv {
  width: 100%;
  min-height: 200px;
  background-size: cover; }

._3hfyQWig9Nl7oM0a8Rp25S ._2W3EblogLDTDPT8QQpDGPe {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px; }

._2pCzFphQn3o4pdNfgzCavh {
  background-size: cover;
  height: 250px;
  background-repeat: no-repeat; }

.zl-P35Af6nRhgwaUEteZc {
  margin-bottom: '15px'; }

._3X_hPkMEm-ELjTSoMh3YbL {
  z-index: 201; }

.G-sCJr1FfIu1MbU8MRmIT {
  width: 20px;
  height: 20px;
  background-color: #e1e1e1;
  position: absolute;
  top: 91%; }

._1JOOlPF5RjEWER4eShrxOP {
  padding-left: 10px; }

._1DmU5cin_TuCzWO3YMTy_W {
  padding-left: 25px;
  font-size: 0.8rem;
  position: absolute;
  top: 92%; }

@media (max-width: 767px) {
  .G-sCJr1FfIu1MbU8MRmIT {
    top: 95%; }
  ._1DmU5cin_TuCzWO3YMTy_W {
    top: 95%; } }

._3Bd0lqMlSyRkdnCGo4LLgN {
  background: white;
  padding: 15px;
  margin-bottom: 15px; }

._2KFvAIHLD99bChlVwOgWIz {
  color: red;
  line-height: 1.3em; }

.J67-CNfotutG2X33UkvBL {
  margin-bottom: 15px; }

._2ZLT70e3LkMpm9s5DCeGy5 {
  margin-top: 15px;
  margin-bottom: 30px;
  text-align: center;
  min-height: 60px; }
  ._2ZLT70e3LkMpm9s5DCeGy5 button {
    width: 100%;
    margin-bottom: 10px; }

._1g6VRe6pgbmTHI2tRXyBv1 {
  padding: 15px;
  padding-left: 80px;
  padding-right: 80px;
  background: #1BA4E2;
  color: white;
  text-transform: uppercase;
  transition: .3s all;
  cursor: pointer;
  border-radius: 30px; }
  ._1g6VRe6pgbmTHI2tRXyBv1 h2 {
    color: white; }
  ._1g6VRe6pgbmTHI2tRXyBv1:hover {
    background: white; }
    ._1g6VRe6pgbmTHI2tRXyBv1:hover h2 {
      color: #1BA4E2; }
  ._1g6VRe6pgbmTHI2tRXyBv1:focus {
    outline: 0; }

._2B_e-KYiGqxIun_dbzhsg4 {
  padding: 10px;
  background: #1075B1;
  margin-bottom: 15px; }
  ._2B_e-KYiGqxIun_dbzhsg4 h2 {
    color: white; }

._2kLXfUlKP9snNCfG8oAu2j {
  padding: 10px;
  background: #E9E9E9;
  margin-bottom: 15px;
  color: black; }

._1VPGs7YpuKvrlNZ2cS-Pdu {
  padding: 10px;
  background: #F7F7F7;
  margin-bottom: 15px; }

._2iTTvDeirren7AklWJoFBG {
  border-bottom: 1px solid #eee; }

._2jHYL4LZroClNaYaXFNPWN {
  display: flex;
  background: white;
  padding: 15px 0;
  border-bottom: 1px solid #eee; }
  ._2jHYL4LZroClNaYaXFNPWN ._2zpt4m1i0nL50BHSyqUIi3 {
    border: 1px solid #D8D8D8;
    width: 133px;
    height: 133px;
    display: flex;
    align-items: center;
    justify-content: center; }
    ._2jHYL4LZroClNaYaXFNPWN ._2zpt4m1i0nL50BHSyqUIi3 img {
      max-width: 131px; }
  ._2jHYL4LZroClNaYaXFNPWN img {
    max-width: 100%;
    max-height: 100%; }

._1Z0W68_ndQbzEOau9y69ox {
  background: white;
  padding: 15px; }

._1q0UgA7JG6-IkWIVo5gfgx {
  padding-left: 15px; }
  ._1q0UgA7JG6-IkWIVo5gfgx ._2Oh01L-MkfWo6VwUD8EHjg {
    font-size: 0.875rem;
    margin-bottom: 10px; }
  ._1q0UgA7JG6-IkWIVo5gfgx ._304s1Tlc2oFWSqxrA3e2F2 {
    font-size: 0.875rem; }
  ._1q0UgA7JG6-IkWIVo5gfgx * {
    margin-bottom: 5px;
    line-height: 20px; }

._1HNSNgIhsgjn2EtRSipUkV {
  margin-bottom: 15px;
  border-bottom: 3px solid #01A3E5;
  display: inline-block; }

._3qc6_eXJlJ_1I-P3BYgW0l {
  margin-bottom: 15px; }

._3vjyn9XK_x5jHrdd5cRCE4 {
  display: flex;
  padding: 15px; }
  ._3vjyn9XK_x5jHrdd5cRCE4 .MDocfgjWRaZ1HDm2s5p0B ._2p3iofea4JMK2JqfRBAGtz {
    color: #417505;
    font-weight: bold; }

._25ZcDZMjKgg2Wt8XqYvb81 {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

._25ZcDZMjKgg2Wt8XqYvb81 input {
  display: none; }

._4jyn9gTpMXYZYSZ5WjmX6 {
  position: absolute;
  top: -3px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #979797; }

._25ZcDZMjKgg2Wt8XqYvb81:hover input ~ ._4jyn9gTpMXYZYSZ5WjmX6 {
  background-color: #eee; }

._25ZcDZMjKgg2Wt8XqYvb81 input:checked ~ ._4jyn9gTpMXYZYSZ5WjmX6 {
  background-color: #fff; }

._4jyn9gTpMXYZYSZ5WjmX6:after {
  content: "";
  position: absolute;
  display: none; }

._25ZcDZMjKgg2Wt8XqYvb81 input:checked ~ ._4jyn9gTpMXYZYSZ5WjmX6:after {
  display: block; }

._25ZcDZMjKgg2Wt8XqYvb81 ._4jyn9gTpMXYZYSZ5WjmX6:after {
  top: 6px;
  left: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #01A3E5; }

._14Hba8AgU-Hpb1z8Bm-Byx {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

._14Hba8AgU-Hpb1z8Bm-Byx input {
  display: none; }

.HAWUhzkld-8icRjtptDp1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #eee;
  border: 1px solid #979797; }

._14Hba8AgU-Hpb1z8Bm-Byx:hover input ~ .HAWUhzkld-8icRjtptDp1 {
  background-color: #ccc; }

._14Hba8AgU-Hpb1z8Bm-Byx input:checked ~ .HAWUhzkld-8icRjtptDp1 {
  background-color: #01A3E5; }

.HAWUhzkld-8icRjtptDp1:after {
  content: "";
  position: absolute;
  display: none; }

._14Hba8AgU-Hpb1z8Bm-Byx input:checked ~ .HAWUhzkld-8icRjtptDp1:after {
  display: block; }

._14Hba8AgU-Hpb1z8Bm-Byx .HAWUhzkld-8icRjtptDp1:after {
  left: 6px;
  top: 2px;
  width: 8px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

@media (max-width: 768px) {
  ._2jHYL4LZroClNaYaXFNPWN {
    flex-direction: column;
    align-items: center; }
  ._1q0UgA7JG6-IkWIVo5gfgx {
    margin-top: 15px; } }

._10oqtZ6X2G19pqtQ7ESXHO {
  padding: 15px 0; }
  ._10oqtZ6X2G19pqtQ7ESXHO ._1JzU6vle9eh3jXhe4DwMAy {
    white-space: nowrap;
    padding: 0;
    border: none;
    box-shadow: none;
    background-color: #fff;
    color: #000;
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    transition: .3s all; }
    ._10oqtZ6X2G19pqtQ7ESXHO ._1JzU6vle9eh3jXhe4DwMAy i {
      margin-left: 2px; }
    ._10oqtZ6X2G19pqtQ7ESXHO ._1JzU6vle9eh3jXhe4DwMAy:focus {
      outline: 0; }
  ._10oqtZ6X2G19pqtQ7ESXHO .l4dPkUKWAIq_tNf1u4XH8 {
    margin: 15px 0;
    padding: 8px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    font-size: 1rem;
    border: 1px solid #dbdbdb;
    color: #0000007d; }
    ._10oqtZ6X2G19pqtQ7ESXHO .l4dPkUKWAIq_tNf1u4XH8:focus {
      outline: 0; }
    ._10oqtZ6X2G19pqtQ7ESXHO .l4dPkUKWAIq_tNf1u4XH8::placeholder {
      font-size: 1.1rem; }

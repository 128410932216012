.p6FNvWEZzkrRpCTf8FRTX,
.U47X3Fq8HFz_voIoJrzTM {
  align-items: center;
  color: #000;
  display: flex; }
  .p6FNvWEZzkrRpCTf8FRTX input,
  .U47X3Fq8HFz_voIoJrzTM input {
    margin-right: 8px; }
  .p6FNvWEZzkrRpCTf8FRTX label,
  .U47X3Fq8HFz_voIoJrzTM label {
    display: flex; }

.p6FNvWEZzkrRpCTf8FRTX {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  font-size: 16px;
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 20px;
  padding-left: 15px;
  border-right: unset; }
  .p6FNvWEZzkrRpCTf8FRTX input,
  .p6FNvWEZzkrRpCTf8FRTX label {
    cursor: pointer; }

.U47X3Fq8HFz_voIoJrzTM {
  font-size: small;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  position: absolute;
  top: 0;
  right: 0; }
  .U47X3Fq8HFz_voIoJrzTM label {
    align-items: center; }

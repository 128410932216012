._2t4EudOJDfaTvfKpRLOsh_ {
  width: 85%;
  background-color: '#FFF'; }

.gUXGLcz14N22VPfyMeZ13 {
  background-color: '#f5f5f5'; }

._2fO348V9-XuuqzIAkUPY4r {
  border: none;
  border-color: transparent;
  padding-left: 20px;
  outline: none;
  width: 800px; }

._2WXJJwbdkp3OL3jzIWX1hH {
  cursor: pointer; }

.AByfx9LmWiGiXaPyB91nU {
  font-size: 0.9rem;
  width: 75vw; }

.oD8Cw6esHwDfHxuJUJux4 {
  font-size: 0.675rem;
  border-color: black;
  border-width: thin;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f5f5f5;
  width: 735px; }

._1uDKyP16tRYmRfd0OYDhze {
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: white; }

.YF6bCOwq8dAWvhi49RxeO {
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5; }

._3HnpRx7ykqXCHWwS-2VtKT {
  background-color: #1387b0;
  padding: 0.5rem 1rem;
  color: white;
  text-decoration: none;
  font-size: 0.775rem;
  font-weight: bold;
  margin-left: 7px;
  cursor: pointer;
  width: 150px; }

@media (max-width: 576px) {
  .YF6bCOwq8dAWvhi49RxeO {
    display: flex;
    flex-direction: column;
    padding-bottom: 0px; }
    .YF6bCOwq8dAWvhi49RxeO .oD8Cw6esHwDfHxuJUJux4 {
      width: 85vw;
      margin-bottom: 10px; }
    .YF6bCOwq8dAWvhi49RxeO ._3HnpRx7ykqXCHWwS-2VtKT {
      margin-left: 0px;
      width: 85vw; } }

._3mBYtsnx40LJtJfgbXPml3 {
  margin-left: 40px;
  color: red; }

.E5-XZxYCbYAd-mBluLqhI {
  margin-left: 170px;
  color: red; }

@media (max-width: 768px) {
  .E5-XZxYCbYAd-mBluLqhI {
    margin-left: 0; } }

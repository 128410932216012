._6LMTcsBeom9OGywyzpXIF {
  border: 1px solid red !important; }

._2oftOCb3XAMXUWraBEM8D5 {
  margin-bottom: 20px; }
  ._2oftOCb3XAMXUWraBEM8D5 input {
    padding: 10px;
    min-width: 100%;
    background-color: white;
    border: 1px solid #ccc; }

.PJD5SjqlHhuWJn8iAeeFw {
  margin-top: 3px; }

._13f7GK1KEYqYq22kDC_7jS {
  margin-bottom: 5px; }

.NDaswVhf6l0q4VZsiqRVw {
  margin-bottom: 5px; }

._3Ptm-Xr_bZJxgfWS_adyPN {
  border-color: red !important; }

._3gDfGsx6F5vCWKFysfrE7H {
  margin-bottom: 15px; }
  ._3gDfGsx6F5vCWKFysfrE7H input {
    padding: 10px;
    min-width: 100%;
    background-color: white;
    border: 1px solid #ccc; }
    ._3gDfGsx6F5vCWKFysfrE7H input:disabled {
      background-color: #eee; }

._3tisEAYcxKu3t7OWc3QDqQ {
  margin-top: 3px; }

._2D-zWPpYQomyzqKF1Cuu_y {
  margin-bottom: 5px; }

._3Uot4caOGTu9b-PqfEq2AD {
  margin-bottom: 5px;
  margin-top: 5px; }

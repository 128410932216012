._3Wu_7CV8N8uBi3DKAnsMfS {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px; }
  ._3Wu_7CV8N8uBi3DKAnsMfS a {
    padding-right: 30px; }

._1LrJXEI6V3Sfpth_7UqHES {
  margin-bottom: 35px;
  margin-right: 10px; }
  ._1LrJXEI6V3Sfpth_7UqHES ._2GmpZwZ2OcWUZK1qLzi8S1 {
    display: none; }
  ._1LrJXEI6V3Sfpth_7UqHES ._3veeImF4MEX4s6onJIpwoH {
    display: inline; }
  ._1LrJXEI6V3Sfpth_7UqHES div {
    margin-top: 15px; }
    ._1LrJXEI6V3Sfpth_7UqHES div p {
      font-size: 0.8rem; }
    ._1LrJXEI6V3Sfpth_7UqHES div a {
      font-size: 0.8rem;
      text-decoration: none;
      color: black; }
  ._1LrJXEI6V3Sfpth_7UqHES .XxguEjS8hwEoTcIF_ik8k {
    font-weight: bold; }

._3YPepigkr9R5Po0SK-9WL6 {
  width: 25px;
  cursor: pointer;
  height: 100%; }

@media (max-width: 576px) {
  ._1LrJXEI6V3Sfpth_7UqHES {
    margin-right: 0px; }
    ._1LrJXEI6V3Sfpth_7UqHES ._2GmpZwZ2OcWUZK1qLzi8S1 {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: auto;
      margin-bottom: 35px; }
      ._1LrJXEI6V3Sfpth_7UqHES ._2GmpZwZ2OcWUZK1qLzi8S1 ._3Wu_7CV8N8uBi3DKAnsMfS a {
        padding-right: 20px;
        padding-left: 20px; }
    ._1LrJXEI6V3Sfpth_7UqHES ._3veeImF4MEX4s6onJIpwoH {
      display: none; }
    ._1LrJXEI6V3Sfpth_7UqHES div {
      margin-top: 10px; }
    ._1LrJXEI6V3Sfpth_7UqHES .XxguEjS8hwEoTcIF_ik8k {
      margin-top: 15px;
      font-weight: bold; }
    ._1LrJXEI6V3Sfpth_7UqHES ._3YPepigkr9R5Po0SK-9WL6 {
      width: 39px; } }

._2Fpx0PTO1ig5wz1kRRQpXi {
  font-size: 0.75rem; }

.fv-Si9l1rzrQRS_1j4AIG {
  margin-bottom: 35px;
  margin-right: 50px;
  max-width: 200px; }
  .fv-Si9l1rzrQRS_1j4AIG:last-child {
    margin-right: 0; }

._2UZdmHM2LjgSzdVNTlEoD6 {
  width: 100%;
  display: inline-block;
  font-weight: bold; }

._3lKlAgoUE87D_U3j1JasE- {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
  cursor: pointer;
  color: #2b2b2b; }
  ._3lKlAgoUE87D_U3j1JasE- a {
    font-size: 0.8rem;
    transition: 0.3s all;
    color: #000000; }
    ._3lKlAgoUE87D_U3j1JasE- a:hover {
      color: #0086b2; }

._1E8Uaxxgz3Y3geLAMgEY2C {
  display: flex;
  flex-direction: row; }

@media (max-width: 768px) {
  ._2UZdmHM2LjgSzdVNTlEoD6 {
    display: block; } }

@media (max-width: 900px) {
  .fv-Si9l1rzrQRS_1j4AIG {
    margin-right: 0;
    width: 50%;
    max-width: none;
    flex-grow: 1; }
  ._1E8Uaxxgz3Y3geLAMgEY2C {
    width: 100%;
    flex-wrap: wrap; } }

@media (max-width: 576px) {
  ._1E8Uaxxgz3Y3geLAMgEY2C {
    flex-direction: column; } }

@font-face {
  font-family: 'dotsfont';
  src: url("/static/fonts/dotsfont.eot");
  src: url("/static/fonts/dotsfont.eot?#iefix") format("embedded-opentype"), url("/static/fonts/dotsfont.svg#font") format("svg"), url("/static/fonts/dotsfont.woff") format("woff"), url("/static/fonts/dotsfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

._3JChf2XcjCM5tt5eSc7W7d {
  border-color: red; }

.h_V-AvNAGZOMDo_AZAYXM {
  margin-bottom: 20px; }
  .h_V-AvNAGZOMDo_AZAYXM input {
    background-color: white;
    border: 1px solid #ccc;
    min-width: 100%;
    padding: 10px; }

.gLbTZKqFcQNgf67Gqi99P {
  margin-bottom: 5px; }

._9-0lvK54MUT13odWWOxm6 {
  resize: vertical;
  width: 100%; }

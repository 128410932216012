._1Msq8ZxTqk9edH2XeSVkvK {
  color: black;
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  width: fit-content; }
  ._1Msq8ZxTqk9edH2XeSVkvK ._263mK32DtrS4gIYaFWX8Kv {
    background-color: #fff;
    border: 1px solid #979797;
    height: 21px;
    margin-right: 10px;
    width: 30px; }
  ._1Msq8ZxTqk9edH2XeSVkvK ._263mK32DtrS4gIYaFWX8Kv[aria-checked='true'] {
    background-color: #01a3e5; }
  ._1Msq8ZxTqk9edH2XeSVkvK ._263mK32DtrS4gIYaFWX8Kv:after {
    border: solid white;
    border-width: 0 2px 2px 0;
    content: '';
    display: none;
    height: 13px;
    left: 6px;
    top: auto;
    position: relative;
    transform: rotate(45deg);
    width: 8px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg); }
  ._1Msq8ZxTqk9edH2XeSVkvK ._263mK32DtrS4gIYaFWX8Kv[aria-checked='true']:after {
    display: block; }
  ._1Msq8ZxTqk9edH2XeSVkvK .JSDEZhUlGfEmq8JD_dt4i {
    display: flex;
    flex-direction: column; }
    ._1Msq8ZxTqk9edH2XeSVkvK .JSDEZhUlGfEmq8JD_dt4i img {
      max-height: 28px;
      max-width: 28px; }
  @media (max-width: 768px) {
    ._1Msq8ZxTqk9edH2XeSVkvK .JSDEZhUlGfEmq8JD_dt4i {
      flex-direction: row; }
    ._1Msq8ZxTqk9edH2XeSVkvK ._263mK32DtrS4gIYaFWX8Kv {
      width: 24px;
      align-self: center; } }
  ._1Msq8ZxTqk9edH2XeSVkvK .pIqhZdZJG5bhheYLBDn0m {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    margin-top: 5px; }
    ._1Msq8ZxTqk9edH2XeSVkvK .pIqhZdZJG5bhheYLBDn0m a {
      color: #337ca3; }
  ._1Msq8ZxTqk9edH2XeSVkvK input {
    display: none; }

@media (min-width: 441px) {
  ._1Msq8ZxTqk9edH2XeSVkvK .JSDEZhUlGfEmq8JD_dt4i {
    flex-direction: row; }
  ._1Msq8ZxTqk9edH2XeSVkvK ._263mK32DtrS4gIYaFWX8Kv {
    width: 21px; } }

._36fQC7RdjktFFly45f9PJw {
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 10px;
  margin-left: 39px;
  font-size: 16px; }

.nGqZB8S_CTwZEIcygm2Ke {
  font-size: 14px;
  margin-top: 15px;
  font-style: italic; }

._1Msq8ZxTqk9edH2XeSVkvK ._263mK32DtrS4gIYaFWX8Kv {
  width: 22px; }

@media (max-width: 768px) {
  ._1Msq8ZxTqk9edH2XeSVkvK ._263mK32DtrS4gIYaFWX8Kv {
    width: 35px; } }

@media (max-width: 481px) {
  ._1Msq8ZxTqk9edH2XeSVkvK ._263mK32DtrS4gIYaFWX8Kv {
    width: 71px; } }

a {
  color: #337ca3; }

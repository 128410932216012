._2XpeAMl70etGMsNDWWMlGp {
  position: relative;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 10px; }

._2x-87JAlcFSTp5fvmx3ZoC {
  position: relative; }

._2ri_RQPAzP7lJOTBT3xUV1 {
  display: inline-block;
  border: 1px solid #eee;
  padding: 10px;
  cursor: pointer; }
  ._2ri_RQPAzP7lJOTBT3xUV1:hover {
    background-color: #eee; }

._18KmNjWb2p-A8X4nxyAMKb {
  position: absolute;
  cursor: pointer;
  right: 1rem; }

._36nhL_mjVOHwg57ENeKUw4 {
  display: inline-block;
  padding: 10px 5px 10px 10px; }
  ._36nhL_mjVOHwg57ENeKUw4 i {
    margin-right: 15px; }

._1nyuUz2q8d2s9c5NlUNiJj {
  color: #ccc; }

._3ud2Ad7zwNZRLIMnPER42n {
  display: flex;
  align-items: center;
  justify-content: center; }

._29zDct2KcvelCD7och7H5q {
  cursor: pointer;
  text-align: center;
  margin-left: 15px; }

._3jciIFRSxTzJrLtuLXJbSZ {
  text-align: center;
  margin-top: 15px; }

._1ISC1D2nv0UM-BVZwnTmiV {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease; }

.vKs1lFBr8KBd7kT221zW_,
._1ISC1D2nv0UM-BVZwnTmiV:hover {
  background-color: #717171; }

/* Next & previous buttons */
._2mZG4hSlukoBGOoPUMbQOF,
._1UWhUvmqdUVKMkG1J6BoCu {
  cursor: pointer;
  margin-top: -80px;
  padding: 16px;
  color: #687689;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  transition: .3s all;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  user-select: none; }

/* Position the "next button" to the right */
._1UWhUvmqdUVKMkG1J6BoCu {
  right: 0;
  border-radius: 3px 0 0 3px; }

/* On hover, add a black background color with a little bit see-through */
._2mZG4hSlukoBGOoPUMbQOF:hover,
._1UWhUvmqdUVKMkG1J6BoCu:hover {
  color: #FFF;
  text-decoration: none; }

._3SirChleXP4H8fuBkc0f0e {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 13px;
  z-index: 5000; }

._2qvqPaJb5qZWYAVAGWHYWC {
  padding: 10px;
  width: 100%;
  margin-bottom: 15px; }
  ._2qvqPaJb5qZWYAVAGWHYWC._384eaUY9aTHuNZRM4ly_v3 {
    margin-bottom: 5px; }

._41UeGXJypHA-DKx3zzWO6 {
  padding: 10px;
  width: 100%;
  margin-bottom: 15px; }

.J2AUmB9-2g8Wfpe2uqIEN {
  display: none; }

._1QY7b7t5KcQG67vxISroGo {
  display: block; }

/* Fading animation */
._2LApjlUQoz5pqxUKS6DqSZ {
  -webkit-animation-name: _2LApjlUQoz5pqxUKS6DqSZ;
  -webkit-animation-duration: 1.5s;
  animation-name: _2LApjlUQoz5pqxUKS6DqSZ;
  animation-duration: 1.5s; }

@-webkit-keyframes _2LApjlUQoz5pqxUKS6DqSZ {
  from {
    opacity: .4; }
  to {
    opacity: 1; } }

@keyframes _2LApjlUQoz5pqxUKS6DqSZ {
  from {
    opacity: .4; }
  to {
    opacity: 1; } }

._2jtPQlDx68kxbndBORCzw_,
._3XGG21CJsjdkaQtT4sfhor {
  width: auto;
  max-height: 200px;
  max-width: 100%; }

._3dl6o1kmYCnTUclqxpsyUF {
  height: 200px; }

._384eaUY9aTHuNZRM4ly_v3 {
  border: 1px solid red !important; }

._2vh5k8RDnRnWgUG7NNwg_c {
  padding: 10px;
  padding-top: 10px;
  background: #f7f7f7;
  margin-top: 10px; }

.uhxJatswni_sODp7ITk_q {
  color: black;
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  width: fit-content; }
  .uhxJatswni_sODp7ITk_q ._3VbTg17PN-vraC1dH_9nlm {
    background-color: #fff;
    border: 1px solid #979797;
    height: 21px;
    margin-right: 10px;
    width: 30px; }
  .uhxJatswni_sODp7ITk_q ._3VbTg17PN-vraC1dH_9nlm[aria-checked='true'] {
    background-color: #01a3e5; }
  .uhxJatswni_sODp7ITk_q ._3VbTg17PN-vraC1dH_9nlm:after {
    border: solid white;
    border-width: 0 2px 2px 0;
    content: '';
    display: none;
    height: 13px;
    left: 6px;
    top: auto;
    position: relative;
    transform: rotate(45deg);
    width: 8px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg); }
  .uhxJatswni_sODp7ITk_q ._3VbTg17PN-vraC1dH_9nlm[aria-checked='true']:after {
    display: block; }
  .uhxJatswni_sODp7ITk_q ._3vBahTikVB7zA6QselwSOq {
    display: flex;
    flex-direction: column; }
    .uhxJatswni_sODp7ITk_q ._3vBahTikVB7zA6QselwSOq img {
      max-height: 28px;
      max-width: 28px; }
  @media (max-width: 768px) {
    .uhxJatswni_sODp7ITk_q ._3vBahTikVB7zA6QselwSOq {
      flex-direction: row; }
    .uhxJatswni_sODp7ITk_q ._3VbTg17PN-vraC1dH_9nlm {
      width: 24px;
      align-self: center; } }
  .uhxJatswni_sODp7ITk_q .YWwtf0md62S5SA66Ics5n {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    margin-top: 5px; }
    .uhxJatswni_sODp7ITk_q .YWwtf0md62S5SA66Ics5n a {
      color: #337ca3; }
  .uhxJatswni_sODp7ITk_q input {
    display: none; }

@media (min-width: 441px) {
  .uhxJatswni_sODp7ITk_q ._3vBahTikVB7zA6QselwSOq {
    flex-direction: row; }
  .uhxJatswni_sODp7ITk_q ._3VbTg17PN-vraC1dH_9nlm {
    width: 21px; } }

.uhxJatswni_sODp7ITk_q ._3VbTg17PN-vraC1dH_9nlm {
  width: 21px;
  transform: scale(0.8);
  margin-right: 6px; }

.uhxJatswni_sODp7ITk_q ._3VbTg17PN-vraC1dH_9nlm:after {
  margin-left: 5px;
  position: unset; }

.rDnTbCMybPHDNtYlNlXpx {
  margin-top: 20px;
  margin-bottom: 40px; }
  .rDnTbCMybPHDNtYlNlXpx ._1swtt-1Vuodui8gckjy-kz,
  .rDnTbCMybPHDNtYlNlXpx ._1IiT-9POio_xcamhNyqI23 {
    font-weight: bold; }
  .rDnTbCMybPHDNtYlNlXpx ._3myFkIquIMbPR_JdVc-qeC {
    margin-bottom: 20px;
    margin-top: 10px; }
  .rDnTbCMybPHDNtYlNlXpx .kf2oKjig3R6WjnAYTTS-M {
    margin-bottom: 24px; }
    .rDnTbCMybPHDNtYlNlXpx .kf2oKjig3R6WjnAYTTS-M .wZKwaqUskaxkMd0Hg57sm {
      border: 1px solid #979797;
      margin-bottom: 16px;
      padding: 10px;
      text-align: justify;
      white-space: pre-line; }
      .rDnTbCMybPHDNtYlNlXpx .kf2oKjig3R6WjnAYTTS-M .wZKwaqUskaxkMd0Hg57sm div {
        margin-bottom: 16px; }
    .rDnTbCMybPHDNtYlNlXpx .kf2oKjig3R6WjnAYTTS-M input {
      margin-right: 6px; }
  .rDnTbCMybPHDNtYlNlXpx ._15cpAKxvgWJc0rCpH6C4D8 {
    margin-top: 10px; }
  .rDnTbCMybPHDNtYlNlXpx ._2vUp8uzAXrSTp_j7DhqDT5 {
    margin-top: 20px;
    margin-bottom: 20px; }

.rDnTbCMybPHDNtYlNlXpx {
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 2147482998;
  display: flex;
  font-size: 15px;
  align-items: center; }

._3RoMGGe6gR7nfi1x5leCqX {
  cursor: pointer;
  color: #337ca3;
  display: inline-block;
  text-decoration: underline;
  font-weight: normal; }

._2R8zBo4bVIld90-AdZyjea {
  line-height: 1.5;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left; }
  ._2R8zBo4bVIld90-AdZyjea :last-child {
    font-weight: bold; }

._2wbDAxidNZdNkAp7NJol6x {
  height: 90vh;
  overflow-y: scroll;
  position: fixed;
  top: 5%;
  width: 900px; }

@media (min-width: 768px) {
  ._2wbDAxidNZdNkAp7NJol6x {
    height: 725px; }
    ._2wbDAxidNZdNkAp7NJol6x .rDnTbCMybPHDNtYlNlXpx {
      margin-bottom: 10px; }
    ._2wbDAxidNZdNkAp7NJol6x ._2R8zBo4bVIld90-AdZyjea {
      text-align: center; }
    ._2wbDAxidNZdNkAp7NJol6x ._2vUp8uzAXrSTp_j7DhqDT5 {
      margin-top: 10px;
      margin-bottom: 10px; } }

@media (max-width: 768px) {
  .rDnTbCMybPHDNtYlNlXpx {
    align-items: start; } }

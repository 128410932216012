._2K0nk0PDoM1QwFt2WtCI9x {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  background-color: white;
  color: black;
  opacity: 0;
  box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.25);
  transition: all 0.25s; }
  ._2K0nk0PDoM1QwFt2WtCI9x ._18laBGRZajv-BOYB21vsW8 {
    border-bottom: 1px solid #d5d5d5;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem; }
    ._2K0nk0PDoM1QwFt2WtCI9x ._18laBGRZajv-BOYB21vsW8 ._1lambOo52XzbiwBDxpKWMz {
      display: flex; }
    ._2K0nk0PDoM1QwFt2WtCI9x ._18laBGRZajv-BOYB21vsW8 ._2pjs1ILmwGFvw8oTPpjhmo {
      color: black;
      text-align: center;
      font-size: 0.9375rem;
      background-color: white; }
    ._2K0nk0PDoM1QwFt2WtCI9x ._18laBGRZajv-BOYB21vsW8 .fs1sMO8UNlMjh1sBL9KnZ {
      position: relative; }
    ._2K0nk0PDoM1QwFt2WtCI9x ._18laBGRZajv-BOYB21vsW8 ._1Y7LYwQAK6dX6fOzGWttdC {
      position: absolute;
      left: 17px;
      top: -7px;
      background: orange;
      color: black !important;
      border-radius: 20px;
      width: 17px;
      height: 17px;
      text-align: center;
      font-size: 0.7rem;
      font-weight: 700;
      padding-top: 1px;
      padding-right: 1px; }
    ._2K0nk0PDoM1QwFt2WtCI9x ._18laBGRZajv-BOYB21vsW8 .-kSjGA9wW_lV4rOfP4onj {
      padding-right: 20px; }
  ._2K0nk0PDoM1QwFt2WtCI9x ._37in5gtUTDTUPDX-7A0OH1 {
    position: absolute;
    width: 100vw;
    top: 50px;
    left: 0;
    height: 100vh;
    transition: transform 0.25s;
    overflow: auto;
    background-color: #f0f0f0; }
    ._2K0nk0PDoM1QwFt2WtCI9x ._37in5gtUTDTUPDX-7A0OH1 ._3r3FIeU1Is0V2NS1AKxjGl {
      font-size: 1rem;
      font-weight: bold;
      position: relative;
      top: -5px; }
  ._2K0nk0PDoM1QwFt2WtCI9x .PhE2vGvSF0Jt-Bv8fCedA {
    transform: translate(0); }
  ._2K0nk0PDoM1QwFt2WtCI9x .XNL3OpCsAliNLWURGeJXE {
    transform: translateX(-100vw) translateY(0); }
  ._2K0nk0PDoM1QwFt2WtCI9x ._1JnZ5jp2ybmfergCMaklAv {
    transform: translateX(100vw) translateY(0);
    visibility: hidden; }
    ._2K0nk0PDoM1QwFt2WtCI9x ._1JnZ5jp2ybmfergCMaklAv ._2mcPrz1dIzpSUyWLW-c4av {
      font-size: 0.8rem;
      border-bottom: 1px solid #f0f0f0;
      padding-top: 20px;
      padding-bottom: 10px;
      text-align: center;
      background-color: white; }
      ._2K0nk0PDoM1QwFt2WtCI9x ._1JnZ5jp2ybmfergCMaklAv ._2mcPrz1dIzpSUyWLW-c4av ._23rXDvU_JPNtqzmaH2SR7p {
        position: absolute;
        left: 10px;
        display: flex;
        justify-content: space-between;
        width: 46px; }
      ._2K0nk0PDoM1QwFt2WtCI9x ._1JnZ5jp2ybmfergCMaklAv ._2mcPrz1dIzpSUyWLW-c4av ._2mKrpP4Q2wK2ZEpsKc3NRZ {
        width: 8px; }
      ._2K0nk0PDoM1QwFt2WtCI9x ._1JnZ5jp2ybmfergCMaklAv ._2mcPrz1dIzpSUyWLW-c4av button {
        position: absolute;
        left: 20px;
        font-family: Overpass;
        font-style: normal;
        font-weight: 500;
        font-size: 18px; }
      ._2K0nk0PDoM1QwFt2WtCI9x ._1JnZ5jp2ybmfergCMaklAv ._2mcPrz1dIzpSUyWLW-c4av i {
        margin-right: 5px; }
    ._2K0nk0PDoM1QwFt2WtCI9x ._1JnZ5jp2ybmfergCMaklAv ._2qrCaXHbDyt3eNSm9E9jWS {
      position: absolute;
      left: 0;
      width: 100vw;
      opacity: 1;
      display: flex;
      flex-direction: column; }
      ._2K0nk0PDoM1QwFt2WtCI9x ._1JnZ5jp2ybmfergCMaklAv ._2qrCaXHbDyt3eNSm9E9jWS._7O5U6G5YlECGhBcSL8n4M {
        animation: _2R1sZ1Y_abMwJbWpMSsZJn 0.5s;
        animation-fill-mode: forwards; }
      ._2K0nk0PDoM1QwFt2WtCI9x ._1JnZ5jp2ybmfergCMaklAv ._2qrCaXHbDyt3eNSm9E9jWS._3P0C5Dyw9r4ac55YjYO0yC {
        animation: _2PHLNV4IXDuu21ic8UvKnP 0.5s;
        animation-fill-mode: forwards; }
    ._2K0nk0PDoM1QwFt2WtCI9x ._1JnZ5jp2ybmfergCMaklAv ._3dl0msg-w7hEuZDsK_eM-X {
      width: 100vw;
      visibility: hidden;
      position: absolute;
      left: 0;
      display: flex;
      flex-direction: column; }
      ._2K0nk0PDoM1QwFt2WtCI9x ._1JnZ5jp2ybmfergCMaklAv ._3dl0msg-w7hEuZDsK_eM-X._1yGK4VJ7l_pQGEYyHLQJME {
        animation-name: _35lr31b37pyoi5KuwC4tQK;
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
        visibility: visible; }
      ._2K0nk0PDoM1QwFt2WtCI9x ._1JnZ5jp2ybmfergCMaklAv ._3dl0msg-w7hEuZDsK_eM-X._30V2Hxgsd2QDPZ5FXAuh5V {
        animation-name: _1UcLd5VP2yDF_e2xLD4amy;
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
        visibility: visible; }
  ._2K0nk0PDoM1QwFt2WtCI9x ._1BCqopFalHywlAbqafgbxF {
    outline: none;
    border: none;
    background: transparent;
    font-size: 25px;
    margin: 12px;
    color: #fff; }
  ._2K0nk0PDoM1QwFt2WtCI9x .XZgFxY9GSLSBpshWu0a8_ {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #01a3e5;
    padding: 8px 0; }
  ._2K0nk0PDoM1QwFt2WtCI9x .Hs_BuXU04yxbIoVU6GeYA {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 20px;
    height: 60px !important;
    background-color: white; }
    ._2K0nk0PDoM1QwFt2WtCI9x .Hs_BuXU04yxbIoVU6GeYA ._2pjs1ILmwGFvw8oTPpjhmo {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 0;
      position: relative; }
      ._2K0nk0PDoM1QwFt2WtCI9x .Hs_BuXU04yxbIoVU6GeYA ._2pjs1ILmwGFvw8oTPpjhmo .cEcHh6ElqwwxNJTXxfQwn {
        line-height: 17px;
        margin-left: 4px;
        font-size: 0.75rem;
        color: #01a3e5; }
      ._2K0nk0PDoM1QwFt2WtCI9x .Hs_BuXU04yxbIoVU6GeYA ._2pjs1ILmwGFvw8oTPpjhmo ._17iRllDEoT2gpfvjX9eOEa {
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 300;
        color: #4a5568; }
      ._2K0nk0PDoM1QwFt2WtCI9x .Hs_BuXU04yxbIoVU6GeYA ._2pjs1ILmwGFvw8oTPpjhmo ._2tvXeaMUCimzR_M8DthRqz:after {
        -webkit-transform: translateY(-50%) rotate(180deg);
        transform: translateY(-50%) rotate(180deg); }
      ._2K0nk0PDoM1QwFt2WtCI9x .Hs_BuXU04yxbIoVU6GeYA ._2pjs1ILmwGFvw8oTPpjhmo ._31hgdToX0SbLUZwWjpN59t {
        color: #fff; }
    ._2K0nk0PDoM1QwFt2WtCI9x .Hs_BuXU04yxbIoVU6GeYA ._1vnHDP2nBOQaTFy2O3RnwK {
      display: flex;
      align-items: center;
      padding: 16px 0; }
      ._2K0nk0PDoM1QwFt2WtCI9x .Hs_BuXU04yxbIoVU6GeYA ._1vnHDP2nBOQaTFy2O3RnwK .S221EMps5ovPpVxWg9pBe {
        color: black;
        font-family: inherit;
        font-style: normal;
        font-weight: bold;
        font-size: 1rem;
        line-height: 32px;
        text-align: center;
        width: 100%; }
        ._2K0nk0PDoM1QwFt2WtCI9x .Hs_BuXU04yxbIoVU6GeYA ._1vnHDP2nBOQaTFy2O3RnwK .S221EMps5ovPpVxWg9pBe ._1wBU5PV8Vy23kc5goe205K {
          position: absolute;
          right: 5%;
          font-size: 35px;
          color: #1f88ae;
          width: 17.5px;
          height: 35px; }
  ._2K0nk0PDoM1QwFt2WtCI9x .skkWhCzY_BkJY5DpfEFz4 {
    display: flex;
    border-bottom: 2px solid #fff;
    background-color: rgba(1, 163, 229, 0.1); }
    ._2K0nk0PDoM1QwFt2WtCI9x .skkWhCzY_BkJY5DpfEFz4 ._17iRllDEoT2gpfvjX9eOEa {
      display: flex;
      padding-left: 16px;
      align-items: center;
      padding-top: 12px;
      padding-bottom: 12px; }
      ._2K0nk0PDoM1QwFt2WtCI9x .skkWhCzY_BkJY5DpfEFz4 ._17iRllDEoT2gpfvjX9eOEa span {
        font-size: 0.75rem;
        color: #4a5568; }
  ._2K0nk0PDoM1QwFt2WtCI9x .IbiC36_c1mObs6CzPz1Ec {
    background-color: #01a3e5; }

._1semnRRnB6HgHKgDZFP6ac {
  transform: translate(100%);
  opacity: 1; }

._3J8PkWuGE1NBB6wEQzYbjT {
  padding: 30px 20px;
  border-top: 1px solid #f0f0f0;
  font-size: 0.9rem;
  background-color: white; }
  ._3J8PkWuGE1NBB6wEQzYbjT ._1v6r_4nsNNzfzapGOaDg58 {
    display: flex;
    align-items: center; }
    ._3J8PkWuGE1NBB6wEQzYbjT ._1v6r_4nsNNzfzapGOaDg58 a {
      text-decoration: none;
      color: black; }
  ._3J8PkWuGE1NBB6wEQzYbjT ._1gg0C65pBAV93aaYswCAtz {
    float: left;
    margin-left: 10px;
    margin-right: 15px;
    width: 19px; }
  ._3J8PkWuGE1NBB6wEQzYbjT ._12neeaP5vwV1WOLy-RoBXs {
    padding-top: 0.2rem;
    font-size: 0.75rem;
    font-weight: 700; }
  ._3J8PkWuGE1NBB6wEQzYbjT ._12neeaP5vwV1WOLy-RoBXs._1Sa19Gbl_nAIhgezf9C0tF {
    color: #eb0000; }

._3MCG8HujOxKht8kCs6srCf {
  background-color: #f0f0f0;
  padding: 20px;
  width: 100%;
  min-height: 14rem;
  flex-grow: 1; }
  ._3MCG8HujOxKht8kCs6srCf .OYwkDOSGx_2_TFiud80Cm {
    display: flex;
    flex-direction: column; }
    ._3MCG8HujOxKht8kCs6srCf .OYwkDOSGx_2_TFiud80Cm a {
      text-decoration: none;
      color: black;
      font-weight: bold; }

@keyframes _2PHLNV4IXDuu21ic8UvKnP {
  0% {
    transform: translateX(0) translateY(0); }
  100% {
    transform: translateX(-100vw) translateY(0); } }

@keyframes _2R1sZ1Y_abMwJbWpMSsZJn {
  0% {
    transform: translateX(0) translateY(0); }
  100% {
    transform: translateX(100vw) translateY(0); } }

@keyframes _35lr31b37pyoi5KuwC4tQK {
  0% {
    transform: translateX(-100vw) translateY(0);
    opacity: 1; }
  100% {
    transform: translateX(0) translateY(0); } }

@keyframes _1UcLd5VP2yDF_e2xLD4amy {
  0% {
    transform: translateX(100vw) translateY(0);
    opacity: 1; }
  100% {
    transform: translateX(0) translateY(0); } }

._3QpCOhLtnIQHlDs32GAlRZ {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px; }
  ._3QpCOhLtnIQHlDs32GAlRZ ._3kyDrEhXkk5NLWcos3SWBx {
    font-size: 16px;
    height: 30px;
    text-align: center;
    width: 50px;
    padding: 1px 6px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #979797;
    box-shadow: 0px 5px 6px 3px #dfdfdf; }
  ._3QpCOhLtnIQHlDs32GAlRZ ._3WZsD8uZ3xbt6wi7MCoft2 {
    outline: none;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #979797;
    box-shadow: 0px 5px 6px 3px #dfdfdf; }

@media (max-width: 1024px) {
  ._3QpCOhLtnIQHlDs32GAlRZ {
    flex-direction: row;
    width: 110px;
    padding-bottom: 0;
    justify-content: center; }
    ._3QpCOhLtnIQHlDs32GAlRZ ._3kyDrEhXkk5NLWcos3SWBx {
      margin-bottom: 10px;
      width: 30px;
      height: 22px; }
  ._3WZsD8uZ3xbt6wi7MCoft2 {
    width: 70px;
    height: 22px; } }

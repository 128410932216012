._2YVhOup54tkuQqmo4dZPB_ {
  background-color: #0a5a81 !important;
  flex-shrink: 0; }
  ._2YVhOup54tkuQqmo4dZPB_ .w_KcpuM_9zNtNCFrWcSfM {
    position: absolute;
    left: 0;
    padding: 2px 16px; }
    ._2YVhOup54tkuQqmo4dZPB_ .w_KcpuM_9zNtNCFrWcSfM .YP6BnDyF7TP-CIOaBEgux {
      margin-right: 8px; }
    ._2YVhOup54tkuQqmo4dZPB_ .w_KcpuM_9zNtNCFrWcSfM ._3YurjGNYCVQFeJDB7BoA_G {
      padding: 5px; }
  ._2YVhOup54tkuQqmo4dZPB_ .k4NVE9sZ1V0rr6-N0BG5G {
    padding: 24px; }
    ._2YVhOup54tkuQqmo4dZPB_ .k4NVE9sZ1V0rr6-N0BG5G .qoEvq1REIC468EWZS2D0d {
      display: inline; }
      @media (max-width: 767px) {
        ._2YVhOup54tkuQqmo4dZPB_ .k4NVE9sZ1V0rr6-N0BG5G .qoEvq1REIC468EWZS2D0d {
          display: block; } }
    ._2YVhOup54tkuQqmo4dZPB_ .k4NVE9sZ1V0rr6-N0BG5G span,
    ._2YVhOup54tkuQqmo4dZPB_ .k4NVE9sZ1V0rr6-N0BG5G a {
      color: #fff;
      font-size: 0.875rem;
      padding: 0 25px;
      text-decoration: none; }
      @media (max-width: 767px) {
        ._2YVhOup54tkuQqmo4dZPB_ .k4NVE9sZ1V0rr6-N0BG5G span,
        ._2YVhOup54tkuQqmo4dZPB_ .k4NVE9sZ1V0rr6-N0BG5G a {
          padding: 0 5px;
          font-size: 0.7rem; } }
      ._2YVhOup54tkuQqmo4dZPB_ .k4NVE9sZ1V0rr6-N0BG5G span._2hq7lrOqDox9hUFhW3wFTs,
      ._2YVhOup54tkuQqmo4dZPB_ .k4NVE9sZ1V0rr6-N0BG5G a._2hq7lrOqDox9hUFhW3wFTs {
        border-left: solid #fff 1px; }
        @media (max-width: 767px) {
          ._2YVhOup54tkuQqmo4dZPB_ .k4NVE9sZ1V0rr6-N0BG5G span._2hq7lrOqDox9hUFhW3wFTs,
          ._2YVhOup54tkuQqmo4dZPB_ .k4NVE9sZ1V0rr6-N0BG5G a._2hq7lrOqDox9hUFhW3wFTs {
            border-left: none; } }

@media (max-width: 767px) {
  ._2YVhOup54tkuQqmo4dZPB_ {
    flex-direction: column; }
    ._2YVhOup54tkuQqmo4dZPB_ .w_KcpuM_9zNtNCFrWcSfM {
      position: relative;
      padding-top: 16px; } }

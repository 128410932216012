._3DDhsn6JifIR7zA2jzDC5T {
  font-size: 0.9rem;
  padding-top: 1rem;
  padding-bottom: 0.9rem;
  font-weight: bold; }

._2VEbWv942NKeAVSibPH2xE {
  font-size: 0.9rem;
  padding-top: 0.5rem;
  font-weight: bold; }

.xLVoFuWZtsCKlfN9ysYU2 {
  font-size: 1.1rem;
  padding-top: 1rem;
  font-weight: bold; }

._21p9RxONvGonvBqWmVZpmg {
  padding-bottom: 2rem; }

._1ZZaHb3vcsZD3ta6-MEuug {
  font-size: 0.9rem;
  line-height: 1.6;
  padding-bottom: 0.9rem; }

._20KinCo1arWgSHnNYG_FUa {
  font-size: 0.9rem;
  padding-bottom: 0.9rem; }

._2UYa_ojXiQ1A0kp9lnC9Xa {
  font-size: 0.9rem;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  width: 100%;
  margin-top: 0.9rem;
  margin-bottom: 0.9rem; }

._3gBIwAF0n9PPp6byLUMdQz {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.n8x7TXL8MZBCTAfNYVHmb {
  padding-bottom: 1rem; }

a {
  color: #337ca3; }

._3xEgfv3zopVPLPA7c3RlR6 {
  padding-bottom: 20px; }
  ._3xEgfv3zopVPLPA7c3RlR6 img {
    width: 100%; }

.Lqm7ouYOLTJIB_3OxQiy7 {
  padding-bottom: 30px; }

._1mYNA3QMwuU4pMIt3PBPcZ {
  padding-left: 20px;
  height: 10rem; }

._12159WfQpJTrQ_u1TBq6VR {
  font-weight: bold;
  margin-bottom: 20px; }

._1HbVS2iFE0NybFqqo5dZ4V {
  font-size: 0.9rem; }

._3nsnxYxpTmtPgb3f5NhaaI {
  margin-top: 20px; }

._1rZiEZKktObGJA7MjgXW1S {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.9rem;
  color: #1387b0; }

._30kRhIB5bnkHAXv4MDRZwG {
  font-weight: bold;
  background-color: #007ea8;
  color: white;
  padding: 0.4rem 0.7rem;
  font-size: 0.7rem;
  border: none;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 1rem;
  white-space: nowrap; }

.yzBPtNn5W-mIj7wUegrbf {
  width: 50%;
  font-size: 0.9rem;
  padding: 0.4rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  border: solid 1px; }
  .yzBPtNn5W-mIj7wUegrbf::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .yzBPtNn5W-mIj7wUegrbf[type='number'] {
    -moz-appearance: textfield; }
  @media (max-width: 768px) {
    .yzBPtNn5W-mIj7wUegrbf {
      width: 100%; } }

._3xEgfv3zopVPLPA7c3RlR6 {
  width: 100%; }

._2Cv3vWsXUubWTisYyCZPw- {
  text-align: center;
  margin-bottom: 2rem; }
  @media (min-width: 1367px) {
    ._2Cv3vWsXUubWTisYyCZPw- {
      display: none; } }

.TPi2FtfdCR-tK81hg9v_D {
  display: flex;
  justify-content: space-evenly; }
  @media (max-width: 768px) {
    .TPi2FtfdCR-tK81hg9v_D {
      display: block; } }

._2QCcO_BhogT6vGFDIn6zIO {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  box-shadow: 0px 0px 8px 2px #d3d3d3;
  margin-bottom: 3.5%;
  width: 150px;
  height: 60px;
  cursor: pointer;
  font-size: 14px;
  padding: 0 0.6vw;
  text-decoration: none;
  font-weight: bold; }
  ._2QCcO_BhogT6vGFDIn6zIO:hover {
    text-decoration: underline; }

@media (max-width: 1024px) {
  ._2QCcO_BhogT6vGFDIn6zIO {
    display: none; } }

._3IkxD6DQrK213NZMrGAiXs {
  color: #337ca3; }

.ZZQdiyWU_QIFdZ0f---3H {
  color: #af3637; }

.Ks_hqjLoEF4sal33uSOLQ {
  display: none; }

.XMI_budEgDdh1S2PwzXZX {
  width: 780px; }

@media (max-width: 1024px) {
  .Ks_hqjLoEF4sal33uSOLQ {
    display: block; }
  .XMI_budEgDdh1S2PwzXZX {
    width: 500px; } }

@media (max-width: 820px) {
  .XMI_budEgDdh1S2PwzXZX {
    width: 400px; } }

._1ujUx0DlMnci3CZGEwDsLN {
  display: block; }

._3yL2sI97YiN_lhabYhZ5Ja {
  display: none; }

._2f5DFEd4oJC4QBcG0lg4dv {
  display: flex;
  justify-content: space-between; }

._2_8xSq3KufItGRXL_9r2iI {
  display: none; }

@media (max-width: 768px) {
  ._1ujUx0DlMnci3CZGEwDsLN {
    display: none; }
  ._3yL2sI97YiN_lhabYhZ5Ja {
    display: block; }
  ._2f5DFEd4oJC4QBcG0lg4dv {
    display: none; }
  ._2_8xSq3KufItGRXL_9r2iI {
    display: block; } }

._2kDu0fmui9eK3UY0bP69i1 {
  font-size: 0.8rem;
  padding-right: 10px;
  cursor: pointer;
  position: relative; }
  ._2kDu0fmui9eK3UY0bP69i1 ._7YIafDRKI9UvSLs8lI1lS {
    display: flex;
    align-items: center; }
  ._2kDu0fmui9eK3UY0bP69i1 .vE5fmslY3nHhkrhI2hG-x {
    float: left;
    font-size: 1.2rem;
    padding-right: 5px;
    width: 21px;
    height: 35px; }
  ._2kDu0fmui9eK3UY0bP69i1 .R7k3nomm9UysSIPSN23S {
    padding-top: 0.2rem;
    font-size: 0.65rem;
    font-weight: 700; }
  ._2kDu0fmui9eK3UY0bP69i1 .R7k3nomm9UysSIPSN23S._3fh2shKB31eenv4Z8P8jeO {
    color: #eb0000; }
  ._2kDu0fmui9eK3UY0bP69i1 ._1DaodZUoRkQ9ThlTSD685b {
    padding-bottom: 0.9rem;
    font-size: 0.9rem; }
  ._2kDu0fmui9eK3UY0bP69i1 .B8lZlwz7k2rqGuVMH68i7 {
    padding-bottom: 0.1rem;
    font-size: 0.9rem;
    text-decoration: underline; }
  ._2kDu0fmui9eK3UY0bP69i1 ._1BvC9Bh4pySawBbpJRXQH4 {
    padding-bottom: 0.1rem;
    font-size: 0.9rem; }
  ._2kDu0fmui9eK3UY0bP69i1 ._2qUTW2CT8RDjTmA7ICuSZH {
    padding-top: 0.9rem;
    padding-bottom: 1rem;
    font-size: 0.9rem;
    font-weight: bold; }

._3vVJlgbPWBDDrT7Qi8gyVz {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 99;
  display: none;
  cursor: default; }

._2QvsUKUpQWUdCK0CHxubmJ {
  background: white;
  border: solid 1px #cccccc;
  display: none;
  position: absolute;
  z-index: 200;
  top: 50px;
  width: 300px;
  max-height: 100vh;
  padding: 20px 10px; }
  ._2QvsUKUpQWUdCK0CHxubmJ ._1rNFCN16s3BppYpU3Elshm {
    font-size: 0.9rem;
    padding-bottom: 1rem;
    font-weight: bold; }
  ._2QvsUKUpQWUdCK0CHxubmJ ._1j0qBInWmriGH-S6rbjco2 {
    font-size: 0.9rem;
    padding-top: 1rem;
    padding-bottom: 0.9rem;
    font-weight: bold; }
  ._2QvsUKUpQWUdCK0CHxubmJ ._2JCnDRqn6tmVCZVUrXZUH1 {
    margin-left: 0;
    width: 160px;
    text-align: center; }
  ._2QvsUKUpQWUdCK0CHxubmJ a {
    background-color: #007ea8;
    padding: 0.5rem 1rem;
    color: white;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    margin-left: 10px; }
  ._2QvsUKUpQWUdCK0CHxubmJ ._1GDIY66qag1MFvLZR126o0 {
    width: 100%;
    text-align: right;
    padding-top: 1rem; }
  ._2QvsUKUpQWUdCK0CHxubmJ ._1UAxbwaqSQRsVqSe4WQpwI {
    background-color: transparent;
    color: #1387b0;
    text-decoration: underline;
    font-size: 0.8rem;
    padding: 0;
    padding-top: 0.5rem; }
  ._2QvsUKUpQWUdCK0CHxubmJ .XqBVcdwmts--NdJdTvut2 {
    position: absolute;
    top: 15px;
    right: 10px;
    color: #636363;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold; }

.Yk0GmU-eYE_FB7aFTC4_j {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

._3Efd4ZUmf47zAC_tYqCZA4 {
  margin-left: 5px;
  width: 10px;
  height: 16px; }

/* Style the accordion section */
._341LidwVT1VCiiKK2KW8t3 {
  display: flex;
  flex-direction: column;
  transition: transform 0.6s ease; }
  ._341LidwVT1VCiiKK2KW8t3 ._3zDzp6Wq-4fpiv9Qo9PmEw {
    display: flex; }
  ._341LidwVT1VCiiKK2KW8t3:last-child {
    border-bottom: 1px #ccc solid; }

/* Style the buttons that are used to open and close the accordion panel */
._3Rj_munamVhE6YU7CjK54s {
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  border-top: 1px #ccc solid;
  border-bottom: none;
  border-left: none;
  border-right: none;
  outline: none; }
  ._3Rj_munamVhE6YU7CjK54s ._3zDzp6Wq-4fpiv9Qo9PmEw {
    border-bottom: none; }

/* Style the accordion content title */
._1zMjMjP7rQt6fGVnr87H6b {
  font-weight: 600;
  font-size: 16px;
  transition: background-color 4s ease; }

/* Style the accordion chevron icon */
.mDBhRuHDPXjOsCpapBx0m {
  margin-left: auto;
  margin-right: 0;
  transition: transform 0.6s ease; }

/* Style to rotate icon when state is active */
._2FnQqkggqI2GjldnzhOHqi {
  transform: rotate(180deg); }

/* Style the accordion content panel. Note: hidden by default */
._1_OIWsDAdjNv3q-mYXfaen {
  background-color: white;
  transition: max-height 0.6s ease;
  height: 100%;
  display: none;
  padding: 0 20px; }
  ._1_OIWsDAdjNv3q-mYXfaen._3zDzp6Wq-4fpiv9Qo9PmEw {
    display: block; }

/* Style the accordion content text */
._3Wv2QRCTbvRs35pfQdVfi8 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px; }

._2rKYDLI2c5KnVVGgbgQf2c ._2JKMNSHAEGhmVoYVpgm3-y {
  padding: 0 !important; }

._2rKYDLI2c5KnVVGgbgQf2c .kvT0Jw8T4a-PF04dYIGeT {
  margin: 10px;
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 3px 0px; }
  ._2rKYDLI2c5KnVVGgbgQf2c .kvT0Jw8T4a-PF04dYIGeT img {
    width: 100%;
    height: auto; }

._2rKYDLI2c5KnVVGgbgQf2c ._1y82_VFZWdhXIY8M72s6Pk {
  text-transform: uppercase; }

._2rKYDLI2c5KnVVGgbgQf2c ._3htYsD3ohbaGD5kRj6gUdZ {
  padding: 20px; }

._2rKYDLI2c5KnVVGgbgQf2c ._1-40yOrMN9q8JUYnAHtwHK {
  border-bottom: 1px solid #eee; }

._2rKYDLI2c5KnVVGgbgQf2c ._3zwSYpuFfolTuF8APrfVm {
  width: 100%;
  min-height: 200px;
  background-size: cover; }

._2rKYDLI2c5KnVVGgbgQf2c ._1kFqFYsl5EAp5I-D6voIin {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px; }

._2rKYDLI2c5KnVVGgbgQf2c .u-MW6tnDUYzIYPLRXAmVf {
  background-size: cover;
  height: 250px;
  background-repeat: no-repeat; }

._2rKYDLI2c5KnVVGgbgQf2c ._37bLNqLvfuyIrAFkWddzRy {
  cursor: pointer;
  text-align: right;
  color: #005983; }

._3tdJq7p5YLOc3DBIjK1MTH {
  text-transform: uppercase; }

._2gznX2IsF5pTPTP7noJUKq {
  cursor: pointer;
  color: #337ca3;
  display: inline-block;
  text-decoration: underline;
  font-weight: normal; }

._2XHKGf11pjOnE13iTga9tF {
  padding-right: 10px; }

._2C9ZUPDe80VDYAdxEjD0d- {
  background-color: white;
  padding: 15px; }
  ._2C9ZUPDe80VDYAdxEjD0d- ._1hTeVlLMjAk_2ImMffOiLi {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    ._2C9ZUPDe80VDYAdxEjD0d- ._1hTeVlLMjAk_2ImMffOiLi ._3_VZzBEXMFtsGgT5MO_8r2 {
      color: #000000;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 2.25rem;
      align-self: flex-start; }
    @media (min-width: 768px) {
      ._2C9ZUPDe80VDYAdxEjD0d- ._1hTeVlLMjAk_2ImMffOiLi ._2eEBtqWKR4qEfh6p-ptzKY {
        display: none; } }
    ._2C9ZUPDe80VDYAdxEjD0d- ._1hTeVlLMjAk_2ImMffOiLi ._2eEBtqWKR4qEfh6p-ptzKY {
      color: #000000;
      font-size: 1.2rem;
      line-height: 2.25rem;
      align-self: flex-end; }
    ._2C9ZUPDe80VDYAdxEjD0d- ._1hTeVlLMjAk_2ImMffOiLi ._2i-pmkMSDwy5wlFqsyp9Mb {
      margin-left: 30px; }
  ._2C9ZUPDe80VDYAdxEjD0d- ._2mutdlFxT9UgfmFinnf5Fv {
    padding-top: 0.9rem;
    padding-bottom: 0.5rem;
    border-top: 2px solid black;
    font-weight: bold;
    font-size: 0.9rem; }
  ._2C9ZUPDe80VDYAdxEjD0d- .xWvfu3nZtuBYx3om8wcEN {
    color: black !important;
    height: 5px; }
  ._2C9ZUPDe80VDYAdxEjD0d- ._3fEdSgXjYK-LgMB7h7LlbK {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    font-weight: bold;
    font-size: 0.9rem; }
  @media (max-width: 768px) {
    ._2C9ZUPDe80VDYAdxEjD0d- ._3fEdSgXjYK-LgMB7h7LlbK {
      display: none; } }

@media (max-width: 767px) {
  ._2C9ZUPDe80VDYAdxEjD0d- ._1hTeVlLMjAk_2ImMffOiLi {
    display: flex;
    border-bottom: 2px solid black; }
    ._2C9ZUPDe80VDYAdxEjD0d- ._1hTeVlLMjAk_2ImMffOiLi ._2i-pmkMSDwy5wlFqsyp9Mb {
      margin-left: 0; } }

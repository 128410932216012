._1WYOO1LG05B1FVMPeIfOgZ {
  border-color: red !important; }

._1TrwbzIiaMQyoJu4GSRlP2 {
  margin-bottom: 15px; }
  ._1TrwbzIiaMQyoJu4GSRlP2 input {
    padding: 10px;
    min-width: 100%;
    background-color: white;
    border: 1px solid #ccc; }
    ._1TrwbzIiaMQyoJu4GSRlP2 input:disabled {
      background-color: #eee; }

._1R179P9BjxCekIonm8ebxo {
  margin-top: 3px; }

._1e1LcbpAg9tl_MfdfZppS6 {
  margin-bottom: 5px; }

._2hdgMYNcbPuE4Ocxft7jWF {
  margin-bottom: 5px;
  margin-top: 5px; }

._1U2ahmEOAuP6tmDXko08ze {
  font-size: 0.9rem;
  padding-top: 15px;
  margin: 0 auto;
  max-width: 900px; }
  ._1U2ahmEOAuP6tmDXko08ze a {
    color: #0086b2; }
  ._1U2ahmEOAuP6tmDXko08ze .Gh53JEcRjnf7VyYF9bC0m,
  ._1U2ahmEOAuP6tmDXko08ze ._3Q_f3XIWUXQ5jifhS30sMd,
  ._1U2ahmEOAuP6tmDXko08ze ._28NH3RFyu66rQnRW1uYWKW {
    line-height: 25px;
    margin-left: 15px;
    margin-right: 15px; }
  ._1U2ahmEOAuP6tmDXko08ze .Gh53JEcRjnf7VyYF9bC0m,
  ._1U2ahmEOAuP6tmDXko08ze ._3Q_f3XIWUXQ5jifhS30sMd {
    padding-bottom: 20px;
    margin-bottom: 20px; }
  ._1U2ahmEOAuP6tmDXko08ze ._3Q_f3XIWUXQ5jifhS30sMd {
    border-bottom: 1px solid black; }
  ._1U2ahmEOAuP6tmDXko08ze .RCnuq-2BYPx13gIYB1blE {
    font-size: 1.5rem;
    font-weight: bolder; }
  ._1U2ahmEOAuP6tmDXko08ze ._1zXUEkaYiH8xcIP_ELyci {
    font-weight: 300; }
  ._1U2ahmEOAuP6tmDXko08ze ._2lHbOpVfPGDnh0J2LVAqHW {
    font-weight: bold;
    font-size: 1.1rem; }
  ._1U2ahmEOAuP6tmDXko08ze .wdHhmExWiQktWQDWd0Nn_ {
    margin-bottom: 20px; }
  ._1U2ahmEOAuP6tmDXko08ze ._nReM43qGFRQb4BiHYqiU {
    font-size: 0.8rem; }
  ._1U2ahmEOAuP6tmDXko08ze ._2FoQgNj1CmhxxvjAaMzHrm .A1RxoGVkSc6MYhYCvDwby {
    height: 96px;
    width: 120px; }
  ._1U2ahmEOAuP6tmDXko08ze ._2FoQgNj1CmhxxvjAaMzHrm ._2bYjmdLCYoRBoXoEgLpcY4 {
    padding-bottom: 8px;
    margin-bottom: 8px;
    line-height: 25px; }
  ._1U2ahmEOAuP6tmDXko08ze ._2FoQgNj1CmhxxvjAaMzHrm ._1058A3lsp9I7PtV5O92mES {
    width: 15%;
    text-align: right; }
  ._1U2ahmEOAuP6tmDXko08ze ._2FoQgNj1CmhxxvjAaMzHrm ._2P6izJNPRwqOJaD5ORzzXR {
    width: 20%; }
  ._1U2ahmEOAuP6tmDXko08ze ._2FoQgNj1CmhxxvjAaMzHrm ._2VifZ0CDY0Cjd3dQBsOl_0 {
    width: 50%; }
    ._1U2ahmEOAuP6tmDXko08ze ._2FoQgNj1CmhxxvjAaMzHrm ._2VifZ0CDY0Cjd3dQBsOl_0 ._20HcGIDG4Zr6k4OYbgxiz8 {
      padding-top: 25px; }
  ._1U2ahmEOAuP6tmDXko08ze ._1RIThW-bvwAFmyo-lTnbCh {
    display: flex; }
    ._1U2ahmEOAuP6tmDXko08ze ._1RIThW-bvwAFmyo-lTnbCh img {
      margin-right: 0.5rem; }
  ._1U2ahmEOAuP6tmDXko08ze ._2Qro3GJbMmZsUEH7J19_CG ._10yD4dzcgHb-PLjpGOaET5 {
    padding: 10px 10px 10px 20px;
    background-color: #ddd; }
    ._1U2ahmEOAuP6tmDXko08ze ._2Qro3GJbMmZsUEH7J19_CG ._10yD4dzcgHb-PLjpGOaET5 ._3_fvSsWRiqGilr-dhcFMy_ {
      font-weight: bold; }
  ._1U2ahmEOAuP6tmDXko08ze ._2p01WPsAr2X8-QzI3P8EnF {
    background-color: #0086b2;
    color: #fff;
    text-align: center;
    padding: 20px 10px;
    margin-bottom: 40px; }
    ._1U2ahmEOAuP6tmDXko08ze ._2p01WPsAr2X8-QzI3P8EnF a {
      color: #fff; }
    ._1U2ahmEOAuP6tmDXko08ze ._2p01WPsAr2X8-QzI3P8EnF ._2Ny_8qIs_sEOgWS8Ah4iOA {
      font-size: 1.6rem;
      font-weight: bold; }
    ._1U2ahmEOAuP6tmDXko08ze ._2p01WPsAr2X8-QzI3P8EnF ._36lSPDZR8D3EFan-ubIDXq {
      font-weight: bold;
      margin-bottom: 8px; }
  ._1U2ahmEOAuP6tmDXko08ze .-PHQeYXEnGgf4v0fwfBXY .H7EtTgnEE3IwsGl2NghPl {
    background-color: #ddd;
    line-height: 18px;
    margin-bottom: 10px;
    padding: 15px 15px 15px 10px; }
    ._1U2ahmEOAuP6tmDXko08ze .-PHQeYXEnGgf4v0fwfBXY .H7EtTgnEE3IwsGl2NghPl ._38iQTh2onkYSKoubnhSFNJ {
      font-weight: bold; }
  ._1U2ahmEOAuP6tmDXko08ze .-PHQeYXEnGgf4v0fwfBXY ._3d1Oeu_iLoFFXjTe-eLna5 {
    font-weight: bold; }
  ._1U2ahmEOAuP6tmDXko08ze .-PHQeYXEnGgf4v0fwfBXY .mEyGG_4WFZxDTge7tYx-l {
    padding-left: 20px;
    display: flex;
    justify-content: space-between; }
    ._1U2ahmEOAuP6tmDXko08ze .-PHQeYXEnGgf4v0fwfBXY .mEyGG_4WFZxDTge7tYx-l ._33HMMrXpua2HJhIxnhawoE {
      font-weight: bold; }
  ._1U2ahmEOAuP6tmDXko08ze .-PHQeYXEnGgf4v0fwfBXY ._1aI-L3G7ZD2-PEsaijWdCR {
    text-align: right; }

._1EHhyskraOFjE8amOmsrzG {
  display: flex; }

.ePmm62iwfgemCJbTqiDgx {
  display: flex;
  justify-content: 'space-between'; }

.yOsIaritRFzeFr9ZIuklA {
  float: right; }

._2NrDadF5tRuMRPmg-VR1Jg {
  display: none; }

@media (max-width: 768px) {
  .yOsIaritRFzeFr9ZIuklA {
    float: none; }
  ._9_MTw1IXK19ZUFy6bPxuq {
    display: none; }
  ._2NrDadF5tRuMRPmg-VR1Jg {
    display: block; }
  ._1EHhyskraOFjE8amOmsrzG {
    display: block; } }

._31-auOQykI4riv8DxLt-Jo {
  font-size: 0.8rem;
  line-height: 16px;
  margin-bottom: 10px;
  margin-top: 10px; }

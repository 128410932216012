._1XLh5i-hfZ0EPIv09KfWkV ._2ln0S26pv0J3UmdPd9fEaz {
  color: #4a4a4a;
  font-size: 0.875rem;
  line-height: 17px;
  border-bottom: 3px solid #01a3e5;
  padding-bottom: 3px; }
  ._1XLh5i-hfZ0EPIv09KfWkV ._2ln0S26pv0J3UmdPd9fEaz ._2WPr57eMAF2DpUXLoeY-w6 {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 17px; }

._1XLh5i-hfZ0EPIv09KfWkV ._1XJuspud2CbEozJ4je7yvp {
  margin-top: 20px; }
  ._1XLh5i-hfZ0EPIv09KfWkV ._1XJuspud2CbEozJ4je7yvp ._3zRvaWi5YYeDsGZIkm1wPi {
    color: #000000;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 4px; }
    ._1XLh5i-hfZ0EPIv09KfWkV ._1XJuspud2CbEozJ4je7yvp ._3zRvaWi5YYeDsGZIkm1wPi .h7CabNJh6PVaQmd9c0KT_ {
      text-decoration: none;
      color: #000000;
      transition: 0.2s; }
    ._1XLh5i-hfZ0EPIv09KfWkV ._1XJuspud2CbEozJ4je7yvp ._3zRvaWi5YYeDsGZIkm1wPi .h7CabNJh6PVaQmd9c0KT_:hover {
      color: #01a3e5;
      transition: 0.2s;
      cursor: pointer; }

._28QljjgX-a1ga9AWTMvjYY {
  margin-top: 30px;
  margin-bottom: 30px; }
  ._28QljjgX-a1ga9AWTMvjYY input {
    padding: 10px;
    padding-left: 45px;
    padding-right: 45px;
    width: 50%; }

._18h3i6OrNTojt6qF1DOSCq {
  max-width: 400px;
  margin-top: 25px;
  position: relative; }
  ._18h3i6OrNTojt6qF1DOSCq input {
    height: 40px;
    color: black;
    font-size: 16px; }
    ._18h3i6OrNTojt6qF1DOSCq input:focus {
      outline: 0; }
    ._18h3i6OrNTojt6qF1DOSCq input::placeholder {
      opacity: 0.8; }
  ._18h3i6OrNTojt6qF1DOSCq .M8A5zXtMCTOHVb33en1DD {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    width: 110px;
    border: 1px solid #fff;
    font-size: 0.8125rem;
    white-space: nowrap; }
  ._18h3i6OrNTojt6qF1DOSCq ._1jwT9M-Q0nsEuvxxATdC4c {
    color: #4c9c1a;
    font-size: 1rem; }
  ._18h3i6OrNTojt6qF1DOSCq ._33WYzs6ozC1m-zh-ACSda {
    color: red;
    font-size: 1rem; }

@media (max-width: 1024px) {
  ._18h3i6OrNTojt6qF1DOSCq .M8A5zXtMCTOHVb33en1DD {
    position: relative;
    width: 100%; } }

.AqKPWURFipgTiqv6KK0UE {
  background: white;
  z-index: 2000;
  opacity: 1;
  position: fixed;
  top: 0;
  width: 100%; }
  .AqKPWURFipgTiqv6KK0UE ._1lkEAszYUhE65rp-ZvmhDN {
    position: relative;
    cursor: pointer; }
    .AqKPWURFipgTiqv6KK0UE ._1lkEAszYUhE65rp-ZvmhDN img {
      width: 25px;
      height: 25px;
      margin: 10px 10px 0 0; }
  .AqKPWURFipgTiqv6KK0UE .KN8rslh4qkRPv34sJ5FjC {
    position: absolute;
    left: 19px;
    top: -6px;
    background: orange;
    color: black !important;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 700;
    padding-top: 1px;
    padding-right: 1px; }
  .AqKPWURFipgTiqv6KK0UE ._1waRJCQcJofJCffvMfpKgj {
    margin: 0 auto;
    max-width: 1320px;
    padding: 0 20px; }
    .AqKPWURFipgTiqv6KK0UE ._1waRJCQcJofJCffvMfpKgj ._3Y9uSZnQ-TAuvrYNnEbq92 {
      font-size: 0.7rem;
      display: flex;
      justify-content: flex-end;
      padding-top: 0.2rem;
      padding-bottom: 0.3rem;
      margin-right: 60px; }
      .AqKPWURFipgTiqv6KK0UE ._1waRJCQcJofJCffvMfpKgj ._3Y9uSZnQ-TAuvrYNnEbq92 a {
        color: #005983;
        padding-left: 1.5rem; }
  .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM {
    height: 100%;
    padding-top: 0.2rem;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 15px !important;
    border-bottom: 1px solid #e0e0e0;
    gap: 20px;
    margin-right: 0 !important;
    justify-content: space-evenly !important; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM .OJzu9khm-9e_ZClv0luyo {
      align-self: flex-end; }
      .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM .OJzu9khm-9e_ZClv0luyo img {
        height: 48px;
        width: 136px; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM .apQF89k3smiFjwTMKKprZ {
      display: flex;
      padding: 0;
      white-space: nowrap;
      height: auto; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._3e7x0HqB4iY9lIQKAReG-8 {
      display: flex; }
      .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._3e7x0HqB4iY9lIQKAReG-8 div {
        display: flex;
        align-items: center; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._3ZpE-yxS5tnoCn7U2bHEiy {
      background: #fbfbfb;
      border: solid 1px #cccccc;
      display: none;
      position: absolute;
      z-index: 200;
      top: 38px;
      width: 200px;
      right: -40px; }
      .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._3ZpE-yxS5tnoCn7U2bHEiy ul {
        list-style: none;
        margin: 0;
        padding: 0; }
        .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._3ZpE-yxS5tnoCn7U2bHEiy ul a {
          text-decoration: none;
          display: block;
          padding: 5px 10px;
          line-height: 23px;
          text-align: center;
          color: #636363;
          font-size: 12px; }
          .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._3ZpE-yxS5tnoCn7U2bHEiy ul a:hover {
            color: #399aca; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._26X0SRqbWrhzGT2U6_7y2S {
      display: block; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._3kRt_qAEJBfcAyoDo6nYZO {
      color: white;
      margin-right: 25px; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._158n-7wUDXPPES4BKQKfPX {
      margin-right: 15px; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM .trwmsrt96IdEKjBjf2H08 {
      margin-right: 15px; }
      .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM .trwmsrt96IdEKjBjf2H08 a {
        text-decoration: none;
        color: white;
        font-size: 0.75rem; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._1JoTK4eESe4eXHaSiVU57w {
      display: flex;
      align-items: baseline; }
      .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._1JoTK4eESe4eXHaSiVU57w .EECwGDDqt8CPty42luN3S {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 0;
        font-size: 1.1rem;
        position: relative;
        vertical-align: top;
        width: auto;
        color: black;
        background: transparent;
        text-decoration: none; }
        .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._1JoTK4eESe4eXHaSiVU57w .EECwGDDqt8CPty42luN3S span:hover {
          text-decoration: none;
          opacity: 0.8; }
        .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._1JoTK4eESe4eXHaSiVU57w .EECwGDDqt8CPty42luN3S ._3oPA37UgmGv92Sq155XHYm {
          display: none; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._21OfY9_V48hc80uopcWCJH {
      font-size: 0.75rem; }

._3Sgon6Ttz_V0Z-LdKrPlze {
  opacity: 0; }

._2ncJKtRfrjE9ksdyqdRqoP {
  background-color: #0a5a81; }
  ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA {
    display: flex;
    font-size: 0.9rem;
    align-items: center;
    height: 30px;
    justify-content: end;
    max-width: 1260px;
    margin: 0 auto; }
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA ._3__EC-MXwSgawcbyR77120 {
      flex: 1 1 auto;
      position: relative;
      text-align: center;
      color: white; }
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA ._3__EC-MXwSgawcbyR77120 + ._3__EC-MXwSgawcbyR77120 {
      border-left: 1px solid white; }
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA a {
      color: white;
      padding-left: 0.4rem; }

._3Abu7weXe4UC1JM93gbCjO {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 99;
  display: none;
  cursor: default; }

._3ezN2viBpGepodFdJ40Vmj {
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3); }
  ._3ezN2viBpGepodFdJ40Vmj ._1waRJCQcJofJCffvMfpKgj ._3Y9uSZnQ-TAuvrYNnEbq92,
  ._3ezN2viBpGepodFdJ40Vmj .woSHPL3zo-MK4H_HejINT {
    display: none; }
  ._3ezN2viBpGepodFdJ40Vmj .o-RjDemO-BuzvFl5MrhUM .OJzu9khm-9e_ZClv0luyo {
    width: 82px;
    height: 31px; }

.msp2vd2y4CJEdwHC-zrOq {
  display: none;
  justify-content: center;
  align-items: center;
  height: 120px;
  background-color: white;
  top: 0%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
  position: fixed;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  transition: opacity 0.3s ease-out;
  opacity: 0;
  z-index: 0; }
  .msp2vd2y4CJEdwHC-zrOq .CMEpwiqhIWgFwQvLIhUEI {
    cursor: pointer;
    width: 11px;
    height: 16px;
    vertical-align: middle; }

._2UZSNweh35QUTz7RPXzqz_ {
  display: flex;
  opacity: 1;
  z-index: 99999; }

@media (max-width: 1024px) {
  .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM {
    padding: 0; }
  ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA {
    margin-bottom: 0.8rem !important;
    display: block;
    text-align: center;
    margin: 0 auto;
    position: relative;
    padding: 0.6rem; }
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA ._3__EC-MXwSgawcbyR77120 {
      font-size: 0.7rem; }
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA ._3__EC-MXwSgawcbyR77120 + ._3__EC-MXwSgawcbyR77120 {
      border-left: none; }
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA .F-XpShToyb4CjHUijhfxt,
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA ._2U8d4AcFR_FlAMgZNqmRAW,
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA ._1n0buGK3vEDrxBJSfbxSKC,
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA ._33lEbPps3G6NHFVoajSyhv,
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA ._3UYrwE-IVreX0Lgbhe8SYd {
      color: white;
      position: absolute;
      display: block;
      width: 100%;
      margin: 0 auto;
      right: 0;
      text-align: center;
      animation-duration: 20s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite; }
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA .F-XpShToyb4CjHUijhfxt {
      animation-name: _2743QkZBgvbWFAgSO7B2mq; }
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA ._2U8d4AcFR_FlAMgZNqmRAW {
      animation-name: _1NAX7uOZttkcs0kbXJfCBL; }
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA ._1n0buGK3vEDrxBJSfbxSKC {
      animation-name: _1Y-dL0bcuD3MSS74eOX1gP; }
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA ._33lEbPps3G6NHFVoajSyhv {
      animation-name: _39QQDxM2zYT19UAwTLtJJP; }
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA ._3UYrwE-IVreX0Lgbhe8SYd {
      animation-name: _3dQyKFFgSzZfHS61Dx7Q3e; }
    ._2ncJKtRfrjE9ksdyqdRqoP ._1LsfPHQ6VlRCMxw_1Q3ceA a {
      color: white;
      padding-left: 0.4rem; } }

@media (max-width: 767px) {
  .AqKPWURFipgTiqv6KK0UE {
    position: relative;
    background-color: white;
    display: flex;
    justify-content: center; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._3kRt_qAEJBfcAyoDo6nYZO {
      color: black; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._158n-7wUDXPPES4BKQKfPX {
      margin-right: 0; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM .trwmsrt96IdEKjBjf2H08 {
      display: none !important; }
    .AqKPWURFipgTiqv6KK0UE .o-RjDemO-BuzvFl5MrhUM ._1JoTK4eESe4eXHaSiVU57w {
      display: none; } }

@keyframes _2743QkZBgvbWFAgSO7B2mq {
  0%,
  8.3% {
    left: -100%;
    opacity: 0; }
  8.3%,
  25% {
    left: 0;
    opacity: 1; }
  33.33%,
  100% {
    left: 110%;
    opacity: 0; } }

@keyframes _1NAX7uOZttkcs0kbXJfCBL {
  0%,
  33.33% {
    left: -100%;
    opacity: 0; }
  41.63%,
  58.29% {
    left: 0;
    opacity: 1; }
  66.66%,
  100% {
    left: 110%;
    opacity: 0; } }

@keyframes _1Y-dL0bcuD3MSS74eOX1gP {
  0%,
  66.66% {
    left: -100%;
    opacity: 0; }
  74.96%,
  91.62% {
    left: 0;
    opacity: 1; }
  100% {
    left: 110%;
    opacity: 0; } }

@keyframes _39QQDxM2zYT19UAwTLtJJP {
  0%,
  10% {
    left: -100%;
    opacity: 0; }
  10%,
  40% {
    left: 0;
    opacity: 1; }
  50%,
  100% {
    left: 110%;
    opacity: 0; } }

@keyframes _3dQyKFFgSzZfHS61Dx7Q3e {
  0%,
  50% {
    left: -100%;
    opacity: 0; }
  60%,
  85% {
    left: 0;
    opacity: 1; }
  95%,
  100% {
    left: 110%;
    opacity: 0; } }

._25nZQAAHDGwL3Fkk8UQlcW {
  padding-right: 10px; }
  ._25nZQAAHDGwL3Fkk8UQlcW a {
    text-decoration: none; }

._3rp1uO3G8YF8HI-I-p4WaX {
  border-left: 1px solid #fff;
  padding-right: 10px; }
  ._3rp1uO3G8YF8HI-I-p4WaX a {
    text-decoration: none; }

@media (max-width: 1024px) {
  ._25nZQAAHDGwL3Fkk8UQlcW {
    display: none; }
  ._3rp1uO3G8YF8HI-I-p4WaX {
    display: none; } }

@media screen and (min-width: 1025px) {
  ._3__EC-MXwSgawcbyR77120 {
    display: none; } }

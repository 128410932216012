._3RBN3AQOVcYVUtWWA7-76Y {
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: #f1f1f1;
  border-radius: 20px; }
  ._3RBN3AQOVcYVUtWWA7-76Y .kDDJZJwGTtoYjpXoZKIIr {
    border-radius: 20px;
    border: none;
    appearance: none;
    background-color: #f1f1f1;
    height: 32px;
    color: #4a4a4a;
    width: 70%;
    padding: 8px;
    padding-left: 50px;
    font-size: 0.75rem; }
    ._3RBN3AQOVcYVUtWWA7-76Y .kDDJZJwGTtoYjpXoZKIIr:focus {
      outline: 0; }
  ._3RBN3AQOVcYVUtWWA7-76Y .kDDJZJwGTtoYjpXoZKIIr::-ms-clear {
    display: none; }
  ._3RBN3AQOVcYVUtWWA7-76Y ._1Xyn641Z6hZMAzOtbvTu1L {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    padding: 8px 12px; }
    ._3RBN3AQOVcYVUtWWA7-76Y ._1Xyn641Z6hZMAzOtbvTu1L img {
      height: 16px; }

.I4lQI7561qCV7VTj3Bk0_ {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e8f0;
  padding: 4px 0; }
  .I4lQI7561qCV7VTj3Bk0_ input {
    appearance: none;
    background: transparent;
    border-style: none;
    width: 100%;
    color: #fff;
    margin-right: 8px;
    font-weight: 400;
    line-height: 1.25;
    font-size: 0.875rem;
    letter-spacing: 1px; }
    .I4lQI7561qCV7VTj3Bk0_ input:focus {
      outline: 0; }
    .I4lQI7561qCV7VTj3Bk0_ input::placeholder {
      color: inherit;
      font-weight: inherit;
      font-size: inherit; }
  .I4lQI7561qCV7VTj3Bk0_ button {
    box-shadow: none;
    padding-right: 8px;
    border: none;
    background: inherit;
    cursor: pointer; }

._3L751qPyK18uyngJiz8Rz7 {
  cursor: pointer; }

@media (max-width: 767px) {
  ._3RBN3AQOVcYVUtWWA7-76Y .kDDJZJwGTtoYjpXoZKIIr {
    font-size: 0.75rem; } }

@media screen and (min-width: 1025px) {
  ._3RBN3AQOVcYVUtWWA7-76Y {
    max-width: 700px;
    border: 1px solid #c0c0c0;
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    transition: opacity 0.3s ease-out;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between; }
    ._3RBN3AQOVcYVUtWWA7-76Y .kDDJZJwGTtoYjpXoZKIIr {
      background-color: #ffffff; } }

._107a_vCYzeXUqvkKycYVZ {
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: #f1f1f1;
  border-radius: 5px;
  border: 1px solid #727272; }
  ._107a_vCYzeXUqvkKycYVZ ._2fv-k9Um8O1Yyx1wCIJ9ve {
    border-radius: 20px;
    border: none;
    appearance: none;
    background-color: #f1f1f1;
    height: 32px;
    color: #4a4a4a;
    width: 85%;
    padding: 8px 40px;
    font-size: 0.75rem; }
    ._107a_vCYzeXUqvkKycYVZ ._2fv-k9Um8O1Yyx1wCIJ9ve:focus {
      outline: 0; }
  ._107a_vCYzeXUqvkKycYVZ ._2fv-k9Um8O1Yyx1wCIJ9ve::-ms-clear {
    display: none; }
  ._107a_vCYzeXUqvkKycYVZ ._32gGW2qgFdd9r4FWgqvqVv {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    padding: 8px 12px; }
    ._107a_vCYzeXUqvkKycYVZ ._32gGW2qgFdd9r4FWgqvqVv img {
      height: 16px; }

.yH0TKENd9SsrYZoWTeWdD {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e8f0;
  padding: 4px 0; }
  .yH0TKENd9SsrYZoWTeWdD input {
    appearance: none;
    background: transparent;
    border-style: none;
    width: 100%;
    color: #fff;
    margin-right: 8px;
    font-weight: 400;
    line-height: 1.25;
    font-size: 0.875rem;
    letter-spacing: 1px; }
    .yH0TKENd9SsrYZoWTeWdD input:focus {
      outline: 0; }
    .yH0TKENd9SsrYZoWTeWdD input::placeholder {
      color: inherit;
      font-weight: inherit;
      font-size: inherit; }
  .yH0TKENd9SsrYZoWTeWdD button {
    box-shadow: none;
    padding-right: 8px;
    border: none;
    background: inherit;
    cursor: pointer; }

._2Haa3qlaE7u2CnIqDKFJxp {
  cursor: pointer; }

@media (max-width: 767px) {
  ._107a_vCYzeXUqvkKycYVZ ._2fv-k9Um8O1Yyx1wCIJ9ve {
    font-size: 0.75rem; } }

._1loM88eYczjmlG9D0p-D6l {
  position: absolute;
  z-index: 50000000;
  background-color: #fff;
  left: -1px;
  padding: 0px 15px; }

.Geq2JG7hOEMe7lWfkyeie {
  width: 100%;
  border: 2px solid #f7f7f7;
  margin: 5px 0px;
  border-radius: 8px;
  list-style: none;
  padding-left: 1%;
  box-sizing: border-box; }

._3EGgrwElVioHFgH3HXo5N3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  min-height: 45px; }

._1J99cinT1OYUENFMuwS4r4 {
  max-width: 60px;
  max-height: 60px; }
  ._1J99cinT1OYUENFMuwS4r4 img {
    max-width: unset !important;
    height: 100%;
    width: 100%;
    object-fit: contain;
    vertical-align: middle; }

.ibNvBGUofWIK3huIo8ovQ {
  text-align: left;
  margin-left: 15px;
  min-height: auto !important;
  height: auto;
  padding: 5px 0;
  line-height: 1.5em;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #3d3d3d;
  font-weight: 700; }
  .ibNvBGUofWIK3huIo8ovQ a {
    font-size: 12px;
    color: #000;
    text-decoration: none;
    text-transform: capitalize !important; }

._2oYs_bSJ8IGkgunQzChPMC {
  border-top: 2px solid #efefef;
  padding: 10px 0 5px !important;
  margin-bottom: 5px;
  font-size: 18px;
  text-align: left;
  font-weight: 700;
  display: block;
  background-color: white;
  text-transform: uppercase !important; }

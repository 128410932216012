.xsyGrsleiYZ__Zch4a4WL {
  margin-bottom: 20px; }
  .xsyGrsleiYZ__Zch4a4WL input {
    padding: 10px;
    min-width: 100%;
    background-color: white;
    border: 1px solid #ccc; }
  .xsyGrsleiYZ__Zch4a4WL ._40ldgBsXMy2yRkjjyzzR {
    margin-bottom: 5px; }
  .xsyGrsleiYZ__Zch4a4WL ._1Ax-F4irkzAQ2XmmIrqptq {
    display: flex; }
    .xsyGrsleiYZ__Zch4a4WL ._1Ax-F4irkzAQ2XmmIrqptq ._1v1PL7teyeP93U2RK_RVgW {
      display: inline-block; }
      .xsyGrsleiYZ__Zch4a4WL ._1Ax-F4irkzAQ2XmmIrqptq ._1v1PL7teyeP93U2RK_RVgW ._13gp_ktMVtpuybSwHl0cTz {
        width: 20px;
        height: 20px; }
    .xsyGrsleiYZ__Zch4a4WL ._1Ax-F4irkzAQ2XmmIrqptq .ruE2wNhi5lSmauhq1tOsS {
      padding-left: 20px; }
      .xsyGrsleiYZ__Zch4a4WL ._1Ax-F4irkzAQ2XmmIrqptq .ruE2wNhi5lSmauhq1tOsS label {
        font-weight: bold; }
  .xsyGrsleiYZ__Zch4a4WL .BChmDhsKBtJfigtvqzYkp {
    margin-top: 3px; }

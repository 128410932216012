._3EIoSimFp9_aiPV2B49teI {
  color: black; }
  ._3EIoSimFp9_aiPV2B49teI .bMZrNUzwYMaLjsdDNBMCY {
    font-weight: bold;
    padding-bottom: 15px; }
  ._3EIoSimFp9_aiPV2B49teI ._1qaI3uVeTfZH347_sjsyDl {
    max-width: 300px; }
    ._3EIoSimFp9_aiPV2B49teI ._1qaI3uVeTfZH347_sjsyDl p,
    ._3EIoSimFp9_aiPV2B49teI ._1qaI3uVeTfZH347_sjsyDl a {
      font-size: 0.8rem; }

.wbGnqxYXnSK2gRepCUdTu a {
  text-decoration: underline; }
  .wbGnqxYXnSK2gRepCUdTu a:hover {
    color: blue; }

.SwsGnGYIIt701E-hfhSn6 {
  display: flex;
  justify-content: space-around;
  margin-top: 10px; }

._3zwV37_bHt5jS2kegEgkLi {
  font-weight: bold; }

.aLgpIHD-zi5LkzaI2gdig {
  margin-bottom: 20px;
  margin-top: 10px; }

._2J6-amW14beadHdw_nAQcc {
  margin: 15px;
  font-size: 15px;
  align-items: center; }

.fw53YOmir-MlxPonKjms3 {
  padding: 15px 0; }

._3ccYUadtVir8zS7adUY_3u {
  top: 5%;
  width: 510px; }

@media (max-width: 768px) {
  ._3ccYUadtVir8zS7adUY_3u {
    height: 93%;
    overflow-y: scroll; }
  .SwsGnGYIIt701E-hfhSn6 {
    display: flex;
    flex-direction: column-reverse;
    height: 125px; } }

.yqHGoKuNXJGtBwYL2x3if input {
  font-size: 16px; }

._2r5HHx_mqOxbH5X9-UKU0E {
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: center; }

.bxkAxBqI8ko8XRK_WiS0g {
  color: red;
  margin-bottom: 10px; }

._1s133qRH0i30yPsSyfxM1I {
  color: red;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: right; }

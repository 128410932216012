._32XeNM8pt7ZATn17Pi-7vc {
  border: 1px solid red !important; }

._2cMeysxHam55TbOteG_qh5 {
  margin-bottom: 20px; }
  ._2cMeysxHam55TbOteG_qh5 input {
    padding: 10px;
    min-width: 100%;
    background-color: white;
    border: 1px solid #ccc; }

._3kJRDR4yeXUaH0-q4AD6Y- {
  margin-top: 3px; }

.mgk_yXc5O4hxgsiI5IBlj {
  margin-bottom: 5px; }

._eHa_LMV98Ey7HIH1VXZ0 {
  margin-bottom: 5px; }

.nMaU8U5ky8z_Hg9HMJlrN {
  padding: 15px;
  background-color: white;
  border: blue;
  color: black;
  cursor: pointer;
  transition: 0.5s;
  border: 1px solid #ccc;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; }
  .nMaU8U5ky8z_Hg9HMJlrN:hover {
    background-color: #eee;
    color: black; }
  .nMaU8U5ky8z_Hg9HMJlrN a {
    color: inherit;
    text-decoration: none; }
  .nMaU8U5ky8z_Hg9HMJlrN._3CNohFcQjZFZ7Gwk0AgTMq {
    opacity: 0.3;
    cursor: not-allowed; }

._2Phov_mUB2VhP6o0qYASHy {
  width: 100%; }

._2h_ubIqb73zqTYkKw3EuSL {
  border-color: red !important; }

._3GfNXAMtkmg1CR5UEo4JsN {
  margin-bottom: 15px; }
  ._3GfNXAMtkmg1CR5UEo4JsN input {
    padding: 10px;
    min-width: 100%;
    background-color: white;
    border: 1px solid #ccc; }
    ._3GfNXAMtkmg1CR5UEo4JsN input:disabled {
      background-color: #eee; }

.kqjvTYNDGlWcbItFk--4r {
  margin-top: 3px; }

._3Fz-uJPcGdIRbFIQVaCiPW {
  margin-bottom: 5px; }

._2IS4K40jOf7l5cWfjRoHqs {
  margin-bottom: 5px;
  margin-top: 5px; }

._2Qtmgdsqu4G4ml0kkDANO5 {
  border: 1px solid red !important; }

._7RXWM0z2nQr5ab_PzTiH6 {
  margin-bottom: 20px; }
  ._7RXWM0z2nQr5ab_PzTiH6 input {
    padding: 10px;
    min-width: 100%;
    background-color: white;
    border: 1px solid #ccc; }

._3qjFurqAC6KFR3u69vqCxB {
  margin-top: 3px; }

._1_CepjNGySmCLaIa2ZbtIM {
  margin-bottom: 5px; }

._2_HXgVSM1UvTNEwM5QFrQb {
  width: 100%;
  resize: vertical; }

._2BHDZivy8an3A9vUOpnm1- {
  text-align: center;
  font-family: Nunito;
  padding-top: 10px; }

._3JzDIpFKR0LI8GydwL8WRt {
  text-align: left;
  padding: 20px 0; }

.YbOCugs92XuVRNs1XBw9f {
  width: 210px; }

._2HbMWJebUK-zK5lTsZgU6Y {
  font-family: Nunito;
  font-size: 2rem;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px; }
  @media (max-width: 768px) {
    ._2HbMWJebUK-zK5lTsZgU6Y {
      font-size: 1.5rem; } }

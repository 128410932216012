._2UTyVmR3rc7dYbtBNxumMY {
  position: relative;
  border: 1px solid #ccc;
  padding: 20px; }

.y1zhsLn_dqgvq2LdVKlW5 {
  position: relative; }

.f0wjBX_r-bFcRKkJ2KjfJ {
  display: inline-block;
  border: 1px solid #eee;
  padding: 10px;
  cursor: pointer; }
  .f0wjBX_r-bFcRKkJ2KjfJ:hover {
    background-color: #eee; }

._1cMG6ZM2bAFCeap3XWcGKo {
  display: flex;
  align-items: center;
  justify-content: center; }

._1DEpoMWFGUjscvdbc4SoU {
  cursor: pointer;
  text-align: center;
  margin-left: 15px; }

._1WB5N3Z0uJ7WZXt1zyMPo {
  text-align: center;
  margin-top: 15px; }

._3Pysb3OnHEwn14SXneofB4 {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease; }

._1ye-mHreEsGiARhQ1mIrR1,
._3Pysb3OnHEwn14SXneofB4:hover {
  background-color: #717171; }

/* Next & previous buttons */
.nluVx2Fz0uGxZAPZDERtI,
._2AYmltmrgbIg7nLN3Xdz5p {
  cursor: pointer;
  margin-top: -80px;
  padding: 16px;
  color: #687689;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  transition: .3s all;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  user-select: none; }

/* Position the "next button" to the right */
._2AYmltmrgbIg7nLN3Xdz5p {
  right: 0;
  border-radius: 3px 0 0 3px; }

/* On hover, add a black background color with a little bit see-through */
.nluVx2Fz0uGxZAPZDERtI:hover,
._2AYmltmrgbIg7nLN3Xdz5p:hover {
  color: #FFF;
  text-decoration: none; }

._2fSfhY7y8Xg_jenmxG3rK4 {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 13px;
  z-index: 5000; }

.uGNI840B2XfkPIdlPY7UU {
  padding: 10px;
  width: 100%;
  margin-bottom: 15px; }
  .uGNI840B2XfkPIdlPY7UU.S5RJBrrEoE6gEAsWRDWTS {
    margin-bottom: 5px; }

._176oAcUsA7Yxfxrg7voo3a {
  padding: 10px;
  width: 100%;
  margin-bottom: 15px; }

.B7b7Ia64Aitbc_mGVwLv2 {
  display: none; }

._3AZSq3bJdolVQEtGNUm_ag {
  display: block; }

/* Fading animation */
._2n3Ah0rZQ8OaXRIztMFTKa {
  -webkit-animation-name: _2n3Ah0rZQ8OaXRIztMFTKa;
  -webkit-animation-duration: 1.5s;
  animation-name: _2n3Ah0rZQ8OaXRIztMFTKa;
  animation-duration: 1.5s; }

@-webkit-keyframes _2n3Ah0rZQ8OaXRIztMFTKa {
  from {
    opacity: .4; }
  to {
    opacity: 1; } }

@keyframes _2n3Ah0rZQ8OaXRIztMFTKa {
  from {
    opacity: .4; }
  to {
    opacity: 1; } }

._22--awKJDDw-GFAocgp8g5,
._2JPs6PRqcbSiK3Hxi6_QgO {
  width: auto;
  max-height: 200px;
  max-width: 100%; }

.N3ItriyNZ18dBpp1i3HhN {
  height: 200px; }

.S5RJBrrEoE6gEAsWRDWTS {
  border: 1px solid red !important; }

._1MeYb_V0GZvBU9k9p0Ge4d {
  padding: 10px;
  padding-top: 10px;
  background: #f7f7f7;
  margin-top: 10px; }

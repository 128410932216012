._5StP_Aqinzd8zIQ7RuBaz {
  display: block;
  height: 85px; }

._187YE4dfLqZZdbj8OP12y5 {
  display: block;
  height: 112px; }

._1zIzScSW1-mDG4Wvlvn_2s {
  display: none; }

@media (max-width: 1024px) {
  ._5StP_Aqinzd8zIQ7RuBaz {
    display: none; }
  ._1zIzScSW1-mDG4Wvlvn_2s {
    display: block;
    margin-top: -8px; }
    ._1zIzScSW1-mDG4Wvlvn_2s ._3FQxDYcrKl6Qmt8CYyQbtR {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem; } }

._1ogt_3YVL3yvSkicsKY1eq {
  position: absolute;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 6;
  width: 100%;
  height: 7700px;
  margin-top: -1.1rem; }

.h-zuRzfA0h6_HaD2-VwA1 {
  background-color: white;
  padding-bottom: 30px; }

.Vp7_5ZkIsXVOJina_0DaO {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .Vp7_5ZkIsXVOJina_0DaO .r7nAPz4P8gJn__sj2i5xb {
    display: none; }

._3wDqsgKBeWrVqub2qca-T1 {
  display: flex; }
  ._3wDqsgKBeWrVqub2qca-T1 ._2XUDYE_aisYXQfT2-4Heyu:not(:first-child)::before {
    content: ' | ';
    padding-left: 5px; }
  ._3wDqsgKBeWrVqub2qca-T1 span {
    font-size: 0.8rem;
    cursor: pointer; }
    ._3wDqsgKBeWrVqub2qca-T1 span:hover {
      color: #0086b2; }

._17J8gOw4gXk-tXa7lV7-ae p {
  font-size: 0.8rem; }

@media (min-width: 768px) and (max-width: 1024px) {
  ._17J8gOw4gXk-tXa7lV7-ae {
    flex-grow: 2;
    align-self: center; }
    ._17J8gOw4gXk-tXa7lV7-ae p {
      width: 60%; }
  .Vp7_5ZkIsXVOJina_0DaO {
    flex-direction: row-reverse;
    text-align: center; } }

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  ._17J8gOw4gXk-tXa7lV7-ae p {
    width: 75%; }
  .Vp7_5ZkIsXVOJina_0DaO {
    flex-direction: row-reverse;
    text-align: center; } }

@media (max-width: 767px) {
  .Vp7_5ZkIsXVOJina_0DaO {
    display: block;
    text-align: center; } }

@media (max-width: 576px) {
  ._17J8gOw4gXk-tXa7lV7-ae p {
    text-align: left; }
  .Vp7_5ZkIsXVOJina_0DaO .r7nAPz4P8gJn__sj2i5xb {
    display: flex;
    margin-bottom: 25px;
    flex-direction: column; }
    .Vp7_5ZkIsXVOJina_0DaO .r7nAPz4P8gJn__sj2i5xb div {
      text-align: left; }
    .Vp7_5ZkIsXVOJina_0DaO .r7nAPz4P8gJn__sj2i5xb span {
      font-size: 0.8rem;
      cursor: pointer; }
      .Vp7_5ZkIsXVOJina_0DaO .r7nAPz4P8gJn__sj2i5xb span:hover {
        color: #0086b2; }
  .Vp7_5ZkIsXVOJina_0DaO ._3wDqsgKBeWrVqub2qca-T1 {
    display: none; } }

.BUTGBUwiJGVc_t8mQ6T1h {
  margin-top: 10px; }

.Lfo05JKv3yQ3lGasIMt0Q ._1UsfjE-Eb-cfG5fgP2zot5 {
  height: 271px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.Lfo05JKv3yQ3lGasIMt0Q .D7t4XazmvifrDOPfi7G_f {
  display: flex;
  margin-top: 30px;
  margin-bottom: 40px; }
  .Lfo05JKv3yQ3lGasIMt0Q .D7t4XazmvifrDOPfi7G_f ._2Yj3Ew5PF0imkGP3smQukJ {
    width: 70%; }
  .Lfo05JKv3yQ3lGasIMt0Q .D7t4XazmvifrDOPfi7G_f ._348lxG6nojXbi6sA_X1nOA {
    width: 30%;
    padding-left: 50px; }

@media only screen and (max-width: 768px) {
  .Lfo05JKv3yQ3lGasIMt0Q .D7t4XazmvifrDOPfi7G_f {
    display: flex;
    flex-direction: column-reverse;
    padding: 0; }
    .Lfo05JKv3yQ3lGasIMt0Q .D7t4XazmvifrDOPfi7G_f ._2Yj3Ew5PF0imkGP3smQukJ {
      order: 2;
      width: 100%; }
    .Lfo05JKv3yQ3lGasIMt0Q .D7t4XazmvifrDOPfi7G_f ._348lxG6nojXbi6sA_X1nOA {
      width: 100%;
      padding-left: 0; } }

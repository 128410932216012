._20N0sBQkmtowtH2LYQYQjq {
  margin-bottom: 5px; }

._1INrleCa9f93Z4_hQ_WW3X {
  margin-bottom: 15px; }
  ._1INrleCa9f93Z4_hQ_WW3X select {
    padding: 10px;
    min-width: 100%;
    background-color: white;
    border: 1px solid #ccc; }

._2IUUmt_j04JLEjo-JTT9rq {
  font-size: 1rem;
  font-family: 'Overpass', sans-serif; }
  ._2IUUmt_j04JLEjo-JTT9rq:after {
    top: 25px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff; }

@media (max-width: 500px) {
  ._2IUUmt_j04JLEjo-JTT9rq {
    float: none; }
  ._2IUUmt_j04JLEjo-JTT9rq,
  ._2vvSttcm-2pQ3DbddW3HnG {
    width: 100%; } }

._1iv6cvYzvUhN81d3IbaRLN {
  margin-bottom: 20px; }
  ._1iv6cvYzvUhN81d3IbaRLN input {
    padding: 10px;
    min-width: 100%;
    background-color: white;
    border: 1px solid #ccc; }

._1F_XzdaaF230nHpGNXwIHO {
  margin-bottom: 5px; }

._1VIwyqxahjz4EKK2hGF7Bu {
  width: 100%;
  resize: vertical; }

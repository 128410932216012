._2B-LFhe3O8g72OgmU8_54y {
  display: flex;
  width: 100%; }

._2PixDJ_n53KYzcdPBV9q-9 {
  color: red;
  font-size: 16px; }
  @media (max-width: 768px) {
    ._2PixDJ_n53KYzcdPBV9q-9 {
      margin-top: 1em; } }

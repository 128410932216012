.b5DvdE9UM2Xz6njW8yQhg {
  margin-top: 35px;
  margin: 0 auto;
  max-width: 900px; }
  .b5DvdE9UM2Xz6njW8yQhg ._3BVpxHFbU4BMiyDF5Rr4j4 {
    font-size: 2.25rem;
    font-weight: 300;
    color: #000; }
  .b5DvdE9UM2Xz6njW8yQhg .YAiWmwOsRcxSQpMAygLCs {
    margin-top: 20px;
    font-size: 1.75rem;
    font-weight: 300;
    color: #000; }
  .b5DvdE9UM2Xz6njW8yQhg ._18WHogY4I8DOjvKIW8Mtud {
    margin-top: 20px; }
    .b5DvdE9UM2Xz6njW8yQhg ._18WHogY4I8DOjvKIW8Mtud .Kul8A1P1ZOWzaPuJOXG1N {
      margin-top: 15px; }
    .b5DvdE9UM2Xz6njW8yQhg ._18WHogY4I8DOjvKIW8Mtud ._1zjk2XU1nsEmEDKM7_jDKH {
      margin-top: 15px;
      font-size: 1.4375rem;
      margin-bottom: 10px; }

.Xv7scwCoo8AAKKxbIxKKf {
  margin-top: 20px; }

._2HenTXm1u3HeB7xDS4s4Gp:last-child {
  border-bottom: 1px solid #eee; }

._2QMF4PNiEqsTDAES9qmJeG {
  display: flex;
  flex-direction: column; }
  ._2QMF4PNiEqsTDAES9qmJeG ._23Y94GsZ2Fnhu2J-bSeKmu {
    padding-right: 15px; }
  ._2QMF4PNiEqsTDAES9qmJeG ._28-5qHlYV70_zjURwKAPLc ._2yUXz0uZHCU81jzAZalZYx {
    border: none; }
    ._2QMF4PNiEqsTDAES9qmJeG ._28-5qHlYV70_zjURwKAPLc ._2yUXz0uZHCU81jzAZalZYx ._1NQiyMbjIDj0NJjD8H00Ao {
      padding: 0; }

._1l11M5dDb-1-FvbFFSV3jK {
  margin: 30px 0; }
  ._1l11M5dDb-1-FvbFFSV3jK ._1cl6flxKNZ3TBwMhoMXT3l {
    font-size: 1.375rem; }
  ._1l11M5dDb-1-FvbFFSV3jK ._3234VlUDpdnqN-b-XLfyRb {
    margin-top: 15px; }

.h0HxfVoIy_Ff-agZCkevw {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px; }
  .h0HxfVoIy_Ff-agZCkevw button {
    max-width: 400px; }

@media (max-width: 768px) {
  ._2HenTXm1u3HeB7xDS4s4Gp:last-child {
    border-bottom: 1px solid #eee; }
  ._2QMF4PNiEqsTDAES9qmJeG {
    display: flex;
    flex-direction: column; }
    ._2QMF4PNiEqsTDAES9qmJeG ._23Y94GsZ2Fnhu2J-bSeKmu {
      border: none;
      padding: 0; }
    ._2QMF4PNiEqsTDAES9qmJeG ._28-5qHlYV70_zjURwKAPLc {
      padding: 15px 0; } }

._3rOci5pXVHn5lc71G3TOqg {
  display: flex;
  flex-direction: column;
  min-height: 100%; }

._2HFXZqTrVjqcC3klsY_OHF {
  flex: 1 0 auto; }

._1fsLJH28Yg54reO50360Tu {
  flex-direction: row;
  flex-shrink: 0; }

._2xZR7KYHteE3ONf0UuIcP2 {
  height: 100%; }

._2cQIgCih97tubqxCAZezZl {
  padding-top: 15px;
  padding-bottom: 15px; }

.P-P48FnmRZ7QeXdQOFZE {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0 5px;
  height: 50px;
  position: relative; }
  .P-P48FnmRZ7QeXdQOFZE .EeEXGb1gccZI4fU7eC9EX {
    margin: 0 auto; }
    .P-P48FnmRZ7QeXdQOFZE .EeEXGb1gccZI4fU7eC9EX:hover {
      cursor: pointer; }
    .P-P48FnmRZ7QeXdQOFZE .EeEXGb1gccZI4fU7eC9EX a {
      color: black;
      font-size: 16px;
      padding: 0 0.6vw;
      text-decoration: none;
      font-weight: bold; }
      .P-P48FnmRZ7QeXdQOFZE .EeEXGb1gccZI4fU7eC9EX a:hover {
        text-decoration: underline; }
  .P-P48FnmRZ7QeXdQOFZE ._29ybLJILzPM1chw9EnbEOZ a {
    color: #337ca3; }
  .P-P48FnmRZ7QeXdQOFZE ._2sfFVLmIANI5VMM0umY9Ki a {
    color: #af3637; }
  .P-P48FnmRZ7QeXdQOFZE ._2sNCHTyEjlMFrqVc7at4qv {
    position: absolute;
    padding: 0 10px;
    background: white;
    z-index: 1000000;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    border-top: none;
    /* This removes the border from the top */ }
    .P-P48FnmRZ7QeXdQOFZE ._2sNCHTyEjlMFrqVc7at4qv a {
      float: none;
      padding: 0;
      text-decoration: none;
      display: block;
      text-align: left;
      position: relative;
      color: #2b2b2b; }
      .P-P48FnmRZ7QeXdQOFZE ._2sNCHTyEjlMFrqVc7at4qv a:hover {
        color: black; }
    .P-P48FnmRZ7QeXdQOFZE ._2sNCHTyEjlMFrqVc7at4qv ._3WH1HffuFbRfK1TKcfvck- {
      display: flex;
      padding: 30px; }
    .P-P48FnmRZ7QeXdQOFZE ._2sNCHTyEjlMFrqVc7at4qv .Fxl6O0zxtN4aD4bzgDG6q {
      width: 50%; }
      .P-P48FnmRZ7QeXdQOFZE ._2sNCHTyEjlMFrqVc7at4qv .Fxl6O0zxtN4aD4bzgDG6q ._3VMMq064VKOjQ4DdmQAgdR {
        line-height: 30px; }
        .P-P48FnmRZ7QeXdQOFZE ._2sNCHTyEjlMFrqVc7at4qv .Fxl6O0zxtN4aD4bzgDG6q ._3VMMq064VKOjQ4DdmQAgdR:last-child {
          border-bottom: none; }
        .P-P48FnmRZ7QeXdQOFZE ._2sNCHTyEjlMFrqVc7at4qv .Fxl6O0zxtN4aD4bzgDG6q ._3VMMq064VKOjQ4DdmQAgdR a {
          text-transform: none !important; }
          .P-P48FnmRZ7QeXdQOFZE ._2sNCHTyEjlMFrqVc7at4qv .Fxl6O0zxtN4aD4bzgDG6q ._3VMMq064VKOjQ4DdmQAgdR a:hover {
            text-decoration: none;
            color: #399aca !important; }
      .P-P48FnmRZ7QeXdQOFZE ._2sNCHTyEjlMFrqVc7at4qv .Fxl6O0zxtN4aD4bzgDG6q .K0oBipZwHxE8Ry8k5eZvu {
        padding-left: 20px; }
    .P-P48FnmRZ7QeXdQOFZE ._2sNCHTyEjlMFrqVc7at4qv ._129GA8zLVmE7QKe5lviQ7z {
      width: 50%;
      padding-left: 20px;
      border-left: 1px solid #e1e1e1; }
      .P-P48FnmRZ7QeXdQOFZE ._2sNCHTyEjlMFrqVc7at4qv ._129GA8zLVmE7QKe5lviQ7z .giK_pFG_nqRNNvroNsiWJ {
        width: 100%;
        background: white;
        cursor: pointer; }

@media screen and (min-width: 1025px) {
  .P-P48FnmRZ7QeXdQOFZE {
    height: auto;
    font-size: 14px; } }

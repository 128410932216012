.c7_2amyshr7jcchZBMV_E {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  margin-bottom: 15px;
  padding-bottom: 10px; }

.jDlickZy-5i8JfKolASOF {
  display: flex;
  cursor: pointer; }

.L9sFe34AcQykFNCDaYK9l {
  color: #005983;
  cursor: pointer; }

._1MN55akAljNZ2J7uKOqpf_ {
  width: 12px;
  margin-right: 8px; }

._2d57FyOiJTyrqa_Ra698sx {
  width: 215px;
  height: 35px;
  font-weight: bold; }

._2obKddX7USQBn7g-LnwLuN {
  margin-top: 22px;
  margin-bottom: 40px;
  font-weight: bold; }

.hzBWsv6ed0msdwXuBk-t9 {
  cursor: pointer;
  text-decoration: underline;
  color: #337ca3; }

._3mgDZk7Q2yX05tPIHQH6LU {
  font-size: 0.9rem; }

.m5VFE0Guhl_qer4VSJ3H6 {
  background: white;
  border: solid 1px #cccccc;
  display: none;
  position: fixed;
  z-index: 2001;
  width: 520px;
  right: 95px;
  max-height: 80vh;
  overflow-y: scroll;
  padding: 20px;
  top: 105px; }
  .m5VFE0Guhl_qer4VSJ3H6 ._287wFKNDS9I_9xUUVkOqMI {
    align-self: flex-end;
    cursor: pointer;
    width: 14px; }
  .m5VFE0Guhl_qer4VSJ3H6 ._3hxj33VDiBEUkLSjL2cieB {
    font-weight: bold;
    border-bottom: thin solid #000;
    width: 100%; }
  .m5VFE0Guhl_qer4VSJ3H6 .Ka_FpXs7KwiyIt9xK7DLu {
    margin-top: 10px;
    width: 100%; }
    .m5VFE0Guhl_qer4VSJ3H6 .Ka_FpXs7KwiyIt9xK7DLu span {
      float: right; }
    .m5VFE0Guhl_qer4VSJ3H6 .Ka_FpXs7KwiyIt9xK7DLu span:hover {
      opacity: 1 !important; }
  .m5VFE0Guhl_qer4VSJ3H6 ._2PX9E5PFgjVnMBT9nP63KD {
    width: 100%; }
    .m5VFE0Guhl_qer4VSJ3H6 ._2PX9E5PFgjVnMBT9nP63KD ._2nypRPFBweGShk8m4Gc_BI {
      border-bottom: 1px solid grey;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0.8rem 0;
      font-size: 0.9rem; }
      .m5VFE0Guhl_qer4VSJ3H6 ._2PX9E5PFgjVnMBT9nP63KD ._2nypRPFBweGShk8m4Gc_BI ._3nZ5tv8xzkOC_FdM0YVbPV {
        cursor: pointer;
        height: 66px;
        width: 25%; }
      .m5VFE0Guhl_qer4VSJ3H6 ._2PX9E5PFgjVnMBT9nP63KD ._2nypRPFBweGShk8m4Gc_BI ._1K5Ea97mlNOah-0HvDexWd {
        align-self: flex-start;
        width: 18%; }
      .m5VFE0Guhl_qer4VSJ3H6 ._2PX9E5PFgjVnMBT9nP63KD ._2nypRPFBweGShk8m4Gc_BI ._3-q1Mmfnk02csWwq7MtL-N {
        width: 50%; }
        .m5VFE0Guhl_qer4VSJ3H6 ._2PX9E5PFgjVnMBT9nP63KD ._2nypRPFBweGShk8m4Gc_BI ._3-q1Mmfnk02csWwq7MtL-N .zNXMLiumJrQ-Sj-whjo6W {
          cursor: pointer;
          font-weight: bold; }
  .m5VFE0Guhl_qer4VSJ3H6 ._3o2PT2z_CHp8KNDhjtv39h {
    width: 100%; }
    .m5VFE0Guhl_qer4VSJ3H6 ._3o2PT2z_CHp8KNDhjtv39h .Ka_FpXs7KwiyIt9xK7DLu {
      padding: 1rem 0 2rem 0; }
  .m5VFE0Guhl_qer4VSJ3H6 ._3NYPK0AvIpLiWZOIrvU_3S {
    margin: 22px 0 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    .m5VFE0Guhl_qer4VSJ3H6 ._3NYPK0AvIpLiWZOIrvU_3S a {
      background-color: #1387b0;
      padding: 0.8rem 4.5rem;
      color: white;
      text-decoration: none;
      font-size: 0.9rem;
      font-weight: bold; }
    .m5VFE0Guhl_qer4VSJ3H6 ._3NYPK0AvIpLiWZOIrvU_3S ._2KJhLEK_g9x92KoXvS2Nxi {
      background-color: white;
      color: black;
      border: 1px solid black; }
    .m5VFE0Guhl_qer4VSJ3H6 ._3NYPK0AvIpLiWZOIrvU_3S ._3VWdYNR8RzRKbvgQUwiJpk {
      text-decoration: none;
      text-transform: uppercase;
      color: #56b6e4; }
  .m5VFE0Guhl_qer4VSJ3H6 ._1fv288zgEJb5yH48vLqDFf {
    font-size: 0.625rem;
    font-weight: bold;
    padding: 5px;
    text-align: center;
    color: black;
    text-transform: uppercase; }
  .m5VFE0Guhl_qer4VSJ3H6 ._3fzoG3NE2cXDgguAarSyPC {
    color: #0086b2;
    padding: 10px;
    text-align: center; }

._2w6l5928Q0ELJMQx9wCWQZ {
  top: 50px; }

._3_eAF0ZzXF7ZvwYHndHgo3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  right: 250px; }

._1z6RRn-rugD2gIPooUl6iN {
  align-self: start;
  cursor: pointer;
  width: 14px;
  padding-top: 2px; }

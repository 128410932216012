._1o0sjlw4M4y8iqWveNnAua {
  text-align: center; }
  ._1o0sjlw4M4y8iqWveNnAua ul {
    margin-bottom: .5rem; }
    ._1o0sjlw4M4y8iqWveNnAua ul li {
      display: inline-block;
      text-transform: uppercase;
      font-size: 1.15rem; }
      ._1o0sjlw4M4y8iqWveNnAua ul li:before {
        content: '\A0\2022\A0\A0';
        color: #000;
        font-size: 11px; }

._1pwOcSd9-RbqPVYUvDdxKI {
  background-size: cover;
  background-position: 70%;
  background-repeat: no-repeat;
  padding: 30px 0;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
  margin-bottom: -15px; }

.M3gFlTjqsIk-zwkWTW38P {
  margin: 0 auto;
  max-width: 951px !important; }

._1ZhmftsU-sN2QFtnAyNL7P {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 40px;
  min-height: 200px; }
  ._1ZhmftsU-sN2QFtnAyNL7P span {
    font-size: 1.625rem;
    line-height: 36px; }

._1N3RAEQddmEU3XXjoPAVQ6 {
  color: #000;
  font-weight: bold;
  margin-bottom: 32px;
  letter-spacing: 1px;
  font-size: 0.875rem;
  padding-top: 40px; }
  ._1N3RAEQddmEU3XXjoPAVQ6 .PR4S4M1CsK-oCO9zvJyv1 {
    margin-bottom: 12px;
    padding: 0 24px; }
    ._1N3RAEQddmEU3XXjoPAVQ6 .PR4S4M1CsK-oCO9zvJyv1 ._2-LfVVYee1aUNPthlBdl5 {
      padding: 0 8px; }

._1rIyZBB0wfOgHINk22JhgG {
  height: 1px;
  width: 257px;
  background-color: #979797;
  margin: 0 auto;
  margin-bottom: 32px; }

._2f1IGZd81C6q1TcrjkASfI {
  color: black;
  font-weight: 300;
  padding: 0 24px;
  margin-bottom: 32px; }
  ._2f1IGZd81C6q1TcrjkASfI p,
  ._2f1IGZd81C6q1TcrjkASfI h1,
  ._2f1IGZd81C6q1TcrjkASfI h2,
  ._2f1IGZd81C6q1TcrjkASfI h3,
  ._2f1IGZd81C6q1TcrjkASfI h4,
  ._2f1IGZd81C6q1TcrjkASfI h5 {
    margin-top: 15px; }

._2dV9tuo_DYEVxshPKSylS6 {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px; }
  ._2dV9tuo_DYEVxshPKSylS6 .GhZokx8B2oIPQzE9Rl6yQ {
    overflow: hidden;
    display: inline-block;
    text-align: center;
    margin: 0 10px; }
    ._2dV9tuo_DYEVxshPKSylS6 .GhZokx8B2oIPQzE9Rl6yQ a {
      text-decoration: none !important;
      color: black; }

._3zPKE-mHfGi2ScExkOO7QM {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 10px; }

._2zXI-9PEm27NPE0Sx1kuGe {
  background-color: #eaeaea;
  padding: 7px;
  width: fit-content;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 7px; }

._3zPKE-mHfGi2ScExkOO7QM > div {
  display: inline-block; }

.gl2deUUw-KHzRi84rD1qv {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
  background-color: #dbdbdb;
  border-radius: 4px;
  color: #000;
  width: 100%;
  margin-top: 15px;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s all; }
  .gl2deUUw-KHzRi84rD1qv img {
    max-width: 80px;
    margin-right: 3px; }
  .gl2deUUw-KHzRi84rD1qv:focus {
    outline: 0; }

._3o-YD-cR7ZgAxyn8QGih_G {
  margin: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  ._3o-YD-cR7ZgAxyn8QGih_G ._3z3g9s_k8dINMTwHsq4MTd {
    text-align: center; }
    ._3o-YD-cR7ZgAxyn8QGih_G ._3z3g9s_k8dINMTwHsq4MTd div {
      color: #000000;
      font-weight: bold;
      font-size: 0.785rem;
      line-height: 1.3; }
  ._3o-YD-cR7ZgAxyn8QGih_G ._6pFpbfY5hvaiaXXFyR40a {
    background-color: #fff;
    margin-left: 10px;
    display: flex;
    align-items: center; }
    ._3o-YD-cR7ZgAxyn8QGih_G ._6pFpbfY5hvaiaXXFyR40a img {
      width: auto;
      max-width: 100%;
      max-height: 50px; }

._1abLAaEmoyomaQK_EhRsNN {
  color: white;
  background-color: #1387b0;
  border: none;
  border-radius: 0px;
  padding: 15px 40px; }

.zUCpblqBAiofNt-GVo2Xz {
  background-color: #005982;
  color: white;
  padding: 0.8rem;
  text-align: center;
  font-size: 0.8rem; }
  .zUCpblqBAiofNt-GVo2Xz a {
    color: white;
    padding-left: 1rem; }

._2JBA42t91EQUvVmIi8Eiz- {
  color: #aaaaaa;
  padding: 0.8rem;
  text-align: right;
  font-size: 0.95rem; }

._39xxKpZOMnoSZsfmfUDTQC {
  padding: 0.8rem;
  text-align: right;
  font-size: 0.95rem; }
  ._39xxKpZOMnoSZsfmfUDTQC a {
    color: #337ca3;
    font-weight: bold; }

._3j2Y8uoYMMywXgz4T9vjTq {
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  margin-bottom: 20px; }
  ._3j2Y8uoYMMywXgz4T9vjTq ._3I64UqpR4aqrn5qkrz8lkw {
    text-align: center; }
  ._3j2Y8uoYMMywXgz4T9vjTq a {
    color: #337ca3; }

.dZKCL-SqFhPkouxtd8C7Y {
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  display: none; }
  .dZKCL-SqFhPkouxtd8C7Y ._3I64UqpR4aqrn5qkrz8lkw {
    text-align: center; }

._1NYBThb25GKr8ZFTWb8S4h {
  padding-top: 15px; }
  ._1NYBThb25GKr8ZFTWb8S4h ._1LwTKRSPpEIeEGOsukif2s {
    border-bottom: 2px solid black; }
    ._1NYBThb25GKr8ZFTWb8S4h ._1LwTKRSPpEIeEGOsukif2s ._3z3g9s_k8dINMTwHsq4MTd {
      color: #000000;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 2.25rem;
      padding-left: 15px; }
  ._1NYBThb25GKr8ZFTWb8S4h .rzl2ePtjLN5hZYRL4qv-g {
    padding: 10px;
    padding-top: 0; }

.TFSBE_yiExdPVw6ZVWc0g {
  font-size: 0.75rem; }

.sQl4SlXYYEfqi0n6QlHTa {
  padding-top: 15px; }

._2BS2m3l2lyxfuGbRPu2F7- {
  border: none; }
  ._2BS2m3l2lyxfuGbRPu2F7- input[type='radio'] {
    margin-right: 5px; }
    ._2BS2m3l2lyxfuGbRPu2F7- input[type='radio']:hover {
      cursor: pointer; }
  ._2BS2m3l2lyxfuGbRPu2F7- ._37DBAsq4mCO5F-kI9625RC {
    float: right;
    font-size: 0.8rem;
    cursor: pointer; }
  ._2BS2m3l2lyxfuGbRPu2F7- .E4LfDxww2uBWuDYdHLGnj {
    color: #c30b24;
    padding-left: 1.6rem;
    font-size: 0.8rem;
    padding-top: 0.3rem; }
  ._2BS2m3l2lyxfuGbRPu2F7- ._2LnBixXPD7rnLOGsUXWdXt {
    margin-top: 10px; }
  ._2BS2m3l2lyxfuGbRPu2F7- ._1ZCzck1dFk_7n95pGKNMVA {
    text-align: left;
    margin-top: 10px; }

.BB7VkeNLrQni2dRpNVn8B {
  background: white;
  border: solid 1px #cccccc;
  display: none;
  position: absolute;
  z-index: 200;
  top: 52px;
  width: 380px;
  max-height: 100vh;
  overflow-y: scroll;
  padding: 20px 10px; }
  .BB7VkeNLrQni2dRpNVn8B ._1r6Ofmsph7CIOfVc8IaLKU {
    font-size: 0.9rem;
    padding-bottom: 1rem;
    font-weight: bold; }
  .BB7VkeNLrQni2dRpNVn8B a {
    background-color: #337ca3;
    padding: 0.5rem 1rem;
    color: white;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer; }
  .BB7VkeNLrQni2dRpNVn8B ._2gSfGmEbWQNyIpHdih8gLb {
    position: absolute;
    top: 15px;
    right: 10px;
    color: #636363;
    font-size: 20px;
    cursor: pointer; }

.dnstut6pHNPnueimsvFNS {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

._1_CKNCQVz3tydvuJphEz3L {
  color: #337ca3; }

.-NMco_mYTJBNsFe2ONuoZ {
  font-size: 0.75rem; }

._3EE3HeV7sQwl-3kYgWrhR8 {
  cursor: pointer;
  text-decoration: underline;
  color: #215069 !important; }

._2yWwNFaIoRn5VjB63_At5P {
  display: none; }

@media (max-width: 768px) {
  ._2yWwNFaIoRn5VjB63_At5P {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 15px !important;
    text-decoration: none;
    box-shadow: 0px 5px 6px 3px #dedede;
    margin-bottom: 15px; }
    ._2yWwNFaIoRn5VjB63_At5P .XzuuXEtVGeS7avk4yhe18 {
      display: flex; }
    ._2yWwNFaIoRn5VjB63_At5P ._3D8wgQZS9pvVuH_OAGPWf8 {
      font-size: 1.1rem;
      cursor: pointer;
      color: #337ca3;
      display: inline-block;
      font-weight: 400; }
    ._2yWwNFaIoRn5VjB63_At5P ._1rcdF9PFHhpsdGLG7UVzSo {
      width: 12px;
      margin-right: 8px; } }

._3hbEH8RPRSZ79cJJpWn8z6 {
  position: fixed;
  bottom: 0;
  height: 122px;
  z-index: 4998;
  width: 100%;
  background-color: white;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center; }
  ._3hbEH8RPRSZ79cJJpWn8z6 ._3Qc1kFsv-ByKER_gRdNwSO {
    width: 92.5%; }

@media (min-width: 768px) {
  ._3hbEH8RPRSZ79cJJpWn8z6 {
    display: none; } }

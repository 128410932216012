._14q9faN1BvSUUx_Kz0k1uB {
  color: red;
  margin-bottom: 15px; }

._1vN59Ufc36wQD_6QeKozT {
  padding: 10px;
  background-color: #eee;
  margin-bottom: 15px; }

._3gxjOBYoslntNw4Kbp0SND {
  padding: 0 15px; }

._33PKc8otjHj5r3mSkXHi9V {
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
  background-color: #246AAC;
  color: white;
  transition: .3s all; }
  ._33PKc8otjHj5r3mSkXHi9V:hover {
    background-color: white;
    border: 1px solid #246AAC;
    color: #246AAC; }

._8nHkKsNdcdsW5fdhRUlw0 {
  float: left;
  margin-right: 5px; }

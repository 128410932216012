._1IhC2503a963_iFHkqtdoB {
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 5000;
  min-width: 100%;
  min-height: 100%;
  animation: _1B0hS6kUC6rg2z3I_N66pv 0.3s ease; }
  ._1IhC2503a963_iFHkqtdoB._1FZEO15HWZXo9z3YJT5pxn {
    animation: _3i3alThW5BiD76eeZaXAx- 0.3s ease; }

._6dCHIeNHEu0I1mXwfTCIQ {
  position: fixed;
  right: 0;
  top: 0;
  width: 370px;
  height: 100%;
  z-index: 5001;
  background: white;
  animation: _38dFKeHiN_BI79FRovm8Vm 0.3s ease; }
  ._6dCHIeNHEu0I1mXwfTCIQ p,
  ._6dCHIeNHEu0I1mXwfTCIQ div,
  ._6dCHIeNHEu0I1mXwfTCIQ span,
  ._6dCHIeNHEu0I1mXwfTCIQ input {
    color: black; }
  ._6dCHIeNHEu0I1mXwfTCIQ._1FZEO15HWZXo9z3YJT5pxn {
    animation: _30NMqPpKR1_AbCr6H5GXeF 0.3s ease; }
  ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg {
    height: 100%;
    background: white;
    position: relative;
    display: flex;
    flex-flow: column;
    height: 100%; }
    ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg ._3BrAPGoTV06NZyZLGsSs_a {
      padding: 15px;
      cursor: pointer; }
    ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg ._2wmkomT6vhxx906DlWCPzX {
      padding-left: 15px;
      font-size: 1.2rem;
      font-weight: bold; }
    ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg ._3TsTjnWbwcKGjxyEqaoai1 {
      margin: 15px;
      padding: 5px;
      border: 1px solid #eeeeee; }
      ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg ._3TsTjnWbwcKGjxyEqaoai1 ._2r2nomRuNw3OwM_IX8rVZy {
        width: 100px;
        height: 100px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain; }
      ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg ._3TsTjnWbwcKGjxyEqaoai1 ._1qrRlGOKZFQeTk1_3Fgsuc {
        font-size: 1rem;
        font-weight: bold; }
      ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg ._3TsTjnWbwcKGjxyEqaoai1 ._199limYb1dXgSJzweid20g .vK58HchciG7uYP_s2VTz_ {
        position: absolute;
        top: 2px;
        right: 0; }
    ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg ._1JB7uQWT1NNUrftIrQCrQe {
      flex: 0 1 auto; }
    ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg ._2CfAjSDfZ3aJmRaGOBzzx6 {
      flex: 1 1 auto;
      overflow: auto; }
    ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg .XN7HreQrtRy9hB2zCJoqx {
      flex: 0 1 300px;
      border-top: 1px solid #eeeeee;
      margin-top: 25px;
      padding-top: 25px; }
      ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg .XN7HreQrtRy9hB2zCJoqx ._3wjfyuzR_I6tynAKNKKKav {
        text-align: center;
        margin: 10px 0; }
        ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg .XN7HreQrtRy9hB2zCJoqx ._3wjfyuzR_I6tynAKNKKKav ._1MuBht03T6gqRf71hNpFWl {
          cursor: pointer;
          font-size: 0.875rem;
          color: #0074b3;
          font-weight: bold; }
      ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg .XN7HreQrtRy9hB2zCJoqx ._1WR2aD2yex00aNnXqlZeE6 {
        width: 280px;
        margin: auto; }
        ._6dCHIeNHEu0I1mXwfTCIQ ._35Qrn121k2M5pum1_nVQhg .XN7HreQrtRy9hB2zCJoqx ._1WR2aD2yex00aNnXqlZeE6 ._3gooE-74jonRvLZ_RBFDh5 {
          margin-top: -4px;
          margin-right: 10px;
          width: 14px; }

.nqv8Z0O5BvYufqpa10xyy {
  width: 10px;
  color: #0074b3; }

@media (max-width: 768px) {
  ._6dCHIeNHEu0I1mXwfTCIQ {
    width: 100% !important;
    padding: 15px; } }

@keyframes _38dFKeHiN_BI79FRovm8Vm {
  from {
    opacity: 0;
    transform: translateX(100px); }
  to {
    opacity: 1;
    transform: translateX(0); } }

@keyframes _1B0hS6kUC6rg2z3I_N66pv {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes _3i3alThW5BiD76eeZaXAx- {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes _30NMqPpKR1_AbCr6H5GXeF {
  from {
    opacity: 1;
    transform: translateX(0); }
  to {
    opacity: 0;
    transform: translateX(100px); } }

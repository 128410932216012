._14zWqkKVWyvxiae_iDj4uC {
  font-size: 0.9rem;
  padding-bottom: 1rem; }

.X6JHsDh1MwirhKdGvAVeP {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  padding: 0.4rem;
  width: 90%; }

._2NMPnWRFWec6lIfJHSQWAU {
  display: block; }

._3gTcXbOMaD7pLS71R2Tzl {
  display: none; }

._3KtTh600foCQ_9lvTMkJMC {
  visibility: hidden; }

._3jDP61xtONg-CYcAD3BxNc {
  visibility: visible; }

._3SeO98CqEJUdbpqkfvTQUc {
  font-size: 0.75rem;
  margin-bottom: 1rem;
  position: relative; }
  ._3SeO98CqEJUdbpqkfvTQUc ._14m68yd5Ik7r5OaGWCQY01 {
    margin-bottom: 0.2rem; }
  ._3SeO98CqEJUdbpqkfvTQUc ._2TlxOejGE3DcwRzptkNDDA {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  ._3SeO98CqEJUdbpqkfvTQUc .RxFjcZ6gyDlixUVoVY31S {
    font-weight: bold; }
  ._3SeO98CqEJUdbpqkfvTQUc .uOciwv5CZtsQmYmApLrzu {
    display: flex;
    flex-direction: row;
    justify-content: right; }
    ._3SeO98CqEJUdbpqkfvTQUc .uOciwv5CZtsQmYmApLrzu ._2bjpPiBHN4pTJZ2ngnyadW {
      margin-right: 6px;
      width: 10px; }
    ._3SeO98CqEJUdbpqkfvTQUc .uOciwv5CZtsQmYmApLrzu ._2ya7i8Nler6YSfsHW30G9h {
      color: green; }
    ._3SeO98CqEJUdbpqkfvTQUc .uOciwv5CZtsQmYmApLrzu ._2A-XN39wM_D_uOjOBiXcnE {
      color: red; }
  ._3SeO98CqEJUdbpqkfvTQUc ._2MJ-2LmCCoDiOG7N-kByhm {
    color: #007ea8;
    cursor: pointer;
    text-decoration: underline; }
  ._3SeO98CqEJUdbpqkfvTQUc ._2f5z9RTBVxtSisZmXvOohr {
    padding-top: 0.4rem; }
    ._3SeO98CqEJUdbpqkfvTQUc ._2f5z9RTBVxtSisZmXvOohr ._1TgRL-dFKU6iGkxD0AmORO {
      color: #636363;
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      right: 10px;
      top: 5px;
      width: 15px; }
    ._3SeO98CqEJUdbpqkfvTQUc ._2f5z9RTBVxtSisZmXvOohr h3 {
      font-size: 1rem; }
  ._3SeO98CqEJUdbpqkfvTQUc ._1Qlsl8Ie82tuJyRjlQFR27 {
    background: white;
    border: solid 1px #cccccc;
    max-height: 100vh;
    padding: 35px 10px 10px;
    position: absolute;
    top: 0px;
    width: 85vw;
    z-index: 200; }

@media (min-width: 441px) {
  ._2bd5yJL56mM6Sptig-BQau {
    display: flex;
    flex-direction: row;
    justify-content: space-between; } }

@media (min-width: 441px) and (max-width: 768px) {
  ._2bd5yJL56mM6Sptig-BQau {
    width: 90vw; }
  ._3SeO98CqEJUdbpqkfvTQUc {
    width: 300px; }
    ._3SeO98CqEJUdbpqkfvTQUc ._1Qlsl8Ie82tuJyRjlQFR27 {
      width: 300px; } }

@media (min-width: 769px) and (max-width: 1024px) {
  ._2bd5yJL56mM6Sptig-BQau {
    width: 70vw; }
  ._3SeO98CqEJUdbpqkfvTQUc {
    width: 320px; }
    ._3SeO98CqEJUdbpqkfvTQUc ._1Qlsl8Ie82tuJyRjlQFR27 {
      width: 320px; } }

@media (min-width: 1025px) {
  ._3SeO98CqEJUdbpqkfvTQUc {
    width: 370px; }
    ._3SeO98CqEJUdbpqkfvTQUc ._1Qlsl8Ie82tuJyRjlQFR27 {
      width: 370px; } }

._2ifI67_AFRl-WYIHkUXdO1 {
  border-top: 1px solid black;
  padding-top: 20px; }
  ._2ifI67_AFRl-WYIHkUXdO1 p,
  ._2ifI67_AFRl-WYIHkUXdO1 div {
    color: black; }
  ._2ifI67_AFRl-WYIHkUXdO1 ._1E7e9NWhugxLIyAvs3SPZG {
    display: flex; }
  ._2ifI67_AFRl-WYIHkUXdO1 .TItMngANheQo16Rd3Vl9w {
    flex: 1;
    margin-right: 10px; }
  ._2ifI67_AFRl-WYIHkUXdO1 ._2_DD0UjvsN-ro3ksPjG4h4 {
    flex: 1;
    display: flex;
    justify-content: space-between; }
  ._2ifI67_AFRl-WYIHkUXdO1 .xF-KXEX4_rRVeARklMwdi {
    flex: 1;
    max-width: 400px; }

@media (max-width: 767px) {
  ._1E7e9NWhugxLIyAvs3SPZG {
    flex-direction: column; }
  .TItMngANheQo16Rd3Vl9w {
    margin-right: 0 !important;
    padding-top: 20px;
    flex: 1;
    order: 2; }
  ._2_DD0UjvsN-ro3ksPjG4h4 {
    flex: 1;
    order: 3; }
  .xF-KXEX4_rRVeARklMwdi {
    order: 1;
    border-bottom: 1px solid black;
    padding-bottom: 20px; } }

._1-BQ-9oxpCAp-T8SINU7Rj {
  border-bottom: 1px solid #eee; }
  ._1-BQ-9oxpCAp-T8SINU7Rj .uuKA3Pi8URRhZzZuGGCS4 {
    background-color: #F7F7F7;
    padding: 15px; }
  ._1-BQ-9oxpCAp-T8SINU7Rj ._1sb6n_ZsMue-iZN5uYpYs0 {
    color: #000000;
    font-size: 1.375rem;
    font-weight: bold; }
  ._1-BQ-9oxpCAp-T8SINU7Rj .BwkFGN2Ndn359UDvhZgF_ {
    margin-top: 10px;
    padding: 20px 10px;
    background-color: #fff; }
    ._1-BQ-9oxpCAp-T8SINU7Rj .BwkFGN2Ndn359UDvhZgF_ .N4vDFWf8_2WFJbd57BQuu {
      display: flex;
      justify-content: space-between; }
      ._1-BQ-9oxpCAp-T8SINU7Rj .BwkFGN2Ndn359UDvhZgF_ .N4vDFWf8_2WFJbd57BQuu ._326y8cz1vsQWAkGG0GQKBN {
        color: #585858;
        font-weight: bold; }
      ._1-BQ-9oxpCAp-T8SINU7Rj .BwkFGN2Ndn359UDvhZgF_ .N4vDFWf8_2WFJbd57BQuu ._3yCPpeQGl8t4VifqpLIoV_ {
        color: #000000;
        font-weight: bold; }
      ._1-BQ-9oxpCAp-T8SINU7Rj .BwkFGN2Ndn359UDvhZgF_ .N4vDFWf8_2WFJbd57BQuu ._1xFcVe4tTEYMe2O8YmfdqC {
        color: #ce0b24; }
  ._1-BQ-9oxpCAp-T8SINU7Rj .AX3ps9Z8PZYiDeykg6lJb {
    margin: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    ._1-BQ-9oxpCAp-T8SINU7Rj .AX3ps9Z8PZYiDeykg6lJb ._3wqCgOM6v5gB19iR8VKBi_ {
      text-align: center; }
      ._1-BQ-9oxpCAp-T8SINU7Rj .AX3ps9Z8PZYiDeykg6lJb ._3wqCgOM6v5gB19iR8VKBi_ div {
        color: #000000;
        font-size: 0.875rem;
        font-weight: bold; }
    ._1-BQ-9oxpCAp-T8SINU7Rj .AX3ps9Z8PZYiDeykg6lJb ._2GREgR2wno-iQ0wR7sByW5 {
      background-color: #fff;
      margin-left: 10px;
      display: flex;
      align-items: center; }
      ._1-BQ-9oxpCAp-T8SINU7Rj .AX3ps9Z8PZYiDeykg6lJb ._2GREgR2wno-iQ0wR7sByW5 img {
        width: auto;
        max-width: 100%;
        max-height: 50px; }
  ._1-BQ-9oxpCAp-T8SINU7Rj ._2S2Pc-ZSn5uv8r2k-p7ZYo {
    margin-top: 20px; }
    ._1-BQ-9oxpCAp-T8SINU7Rj ._2S2Pc-ZSn5uv8r2k-p7ZYo ._1i1uTjhfH7thAqw54ejJur button {
      width: 100%; }
  ._1-BQ-9oxpCAp-T8SINU7Rj ._3ujSqgDEnzUteUwF6h-Ll_ {
    padding: 0;
    max-width: 500px;
    margin-bottom: 30px; }

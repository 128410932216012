._3N_bUuJqHK5YNkrKqJOTh_ {
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  padding-bottom: 4px; }
  ._3N_bUuJqHK5YNkrKqJOTh_ ._1kkJn0dDj41z53U_FwD4tg {
    margin: 0px;
    margin-top: 6px; }
  ._3N_bUuJqHK5YNkrKqJOTh_ ._37hyFjbJtxZXaoP3ROEfTu,
  ._3N_bUuJqHK5YNkrKqJOTh_ .EhcRHPgs7ABmcrJlMHNfl,
  ._3N_bUuJqHK5YNkrKqJOTh_ ._3UAeodOZe4jkKJnk-siI_q {
    margin-left: 10px; }
  ._3N_bUuJqHK5YNkrKqJOTh_ .EhcRHPgs7ABmcrJlMHNfl,
  ._3N_bUuJqHK5YNkrKqJOTh_ ._3UAeodOZe4jkKJnk-siI_q {
    font-size: 0.65rem; }
  ._3N_bUuJqHK5YNkrKqJOTh_ ._37hyFjbJtxZXaoP3ROEfTu {
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 4px; }
  ._3N_bUuJqHK5YNkrKqJOTh_ .EhcRHPgs7ABmcrJlMHNfl {
    margin-top: 4px; }
  ._3N_bUuJqHK5YNkrKqJOTh_ ._3UAeodOZe4jkKJnk-siI_q {
    color: #215069;
    text-decoration: underline;
    cursor: pointer; }
  ._3N_bUuJqHK5YNkrKqJOTh_ ._24Bqj6-VFlXXucsjNMhXP_ ._37hyFjbJtxZXaoP3ROEfTu,
  ._3N_bUuJqHK5YNkrKqJOTh_ ._24Bqj6-VFlXXucsjNMhXP_ .EhcRHPgs7ABmcrJlMHNfl {
    opacity: 0.5;
    pointer-events: none; }

._2yrmupsFXDq9csXQu09f2l {
  display: flex; }

._14lal3DDX1Y1NMrHoBWxPW {
  align-items: center; }

._3wD9QRMKpnOMpE8e0VprOC {
  justify-content: center; }

._2W0soF-MGHxB82vhY6L14H {
  flex: 1; }

._1vk3WPYtTA6kWYnuX97m9x {
  margin-left: 15px; }

._1udfZnVZheemRQJ7tlUaOt {
  margin-right: 15px; }

._45aePkCETZ9oTVnQNo4FG {
  margin-top: 15px; }

.ypkUGIPlgEKIAxGObv4oz {
  margin-bottom: 15px; }

._2tc_REGdFixF-gKPBBZ4g1 {
  text-align: right; }

.G8Da4psBuwTmlknjTdoST {
  text-align: center; }

._2ZzgJgivfblyWJh8yZDyg4 {
  border-color: #eee;
  border-bottom: 1px solid #eee;
  border-top: none;
  margin-top: 25px;
  margin-bottom: 25px; }

._3WPX_WUChJB8tzf4tAJjI2 {
  overflow: hidden;
  display: inline-block;
  text-align: center; }
  ._3WPX_WUChJB8tzf4tAJjI2 :hover {
    cursor: pointer; }
  ._3WPX_WUChJB8tzf4tAJjI2 a {
    color: black;
    font-size: 16px;
    padding: 0;
    text-transform: none;
    text-decoration: none !important; }
  ._3WPX_WUChJB8tzf4tAJjI2 a:hover {
    color: black; }
  ._3WPX_WUChJB8tzf4tAJjI2 ._3v_Sy58Dm7QkHaW_UfSfe6 {
    margin-left: 10px;
    margin-right: 10px; }

._3WPX_WUChJB8tzf4tAJjI2 .sQAX__dCwj9Mla3NhAb6R {
  font-size: 16px;
  border: none;
  outline: none;
  color: black;
  background-color: inherit;
  padding: 0;
  margin: 0;
  font-weight: bold; }

._3WPX_WUChJB8tzf4tAJjI2:hover .sQAX__dCwj9Mla3NhAb6R {
  background-color: transparent; }

._3WPX_WUChJB8tzf4tAJjI2:hover ._3LME5PnvzJ61EqTKRNEKGn {
  display: block; }

._3_hPs9oLkPFNTTXuF5jkv3 {
  margin: 15px;
  margin-left: 5px;
  margin-right: 5px; }

._3LME5PnvzJ61EqTKRNEKGn {
  display: none;
  position: absolute;
  background: #fbfbfb;
  border: solid 1px #cccccc;
  width: 250px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 1000000; }

._3LME5PnvzJ61EqTKRNEKGn a {
  float: none;
  font-weight: bold;
  color: gray;
  padding: 0;
  text-decoration: none !important;
  display: block;
  text-align: left;
  border-bottom: 1px solid #cccccc;
  position: relative;
  display: block;
  color: #636363; }
  ._3LME5PnvzJ61EqTKRNEKGn a:hover {
    color: black; }

._3s2YKMGcqwXbxHeJlZ1NuR {
  margin-bottom: 15px; }
  ._3s2YKMGcqwXbxHeJlZ1NuR ._1y2kQAh5StgR5SEhVrjff6 {
    font-size: 16px; }
  ._3s2YKMGcqwXbxHeJlZ1NuR ._3iT7bWBE9nEecgm5KIadxP {
    margin-left: 7px;
    margin-right: 7px; }

._2uMBv-EKYYWA51e_Wul8Cd {
  max-width: 1170px;
  margin: 0 auto;
  background: #fff;
  padding: 30px;
  margin-top: 10px;
  z-index: 1;
  position: relative; }

._3y1DiiMu8_p7ngZZ3tqWM {
  position: relative; }

._3y1DiiMu8_p7ngZZ3tqWM:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); }

.pqaBOdnnn_6PnBoJpcDIq {
  background-attachment: fixed !important;
  background-size: cover;
  filter: blur(1px);
  -webkit-filter: blur(1px);
  background-repeat: no-repeat;
  background-position: top center; }
  .pqaBOdnnn_6PnBoJpcDIq ._1f8c6jTOymA9uBMos42Hd8 {
    padding: 150px 0;
    position: relative;
    z-index: 1;
    color: white; }

._1ycxtTMNI8xVlG0NvqUSWf {
  width: 100%; }
  ._1ycxtTMNI8xVlG0NvqUSWf ._2hVg5bJ1yCPlS0uU1YMTVL {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #f5f5f5; }
    ._1ycxtTMNI8xVlG0NvqUSWf ._2hVg5bJ1yCPlS0uU1YMTVL ._1KkfAtwSf5_vRgaBIo4Jd6 {
      font-weight: bold;
      font-size: 1.125rem;
      padding: 1.8rem 0.7rem 1.2rem; }
    ._1ycxtTMNI8xVlG0NvqUSWf ._2hVg5bJ1yCPlS0uU1YMTVL .xoZzjubeO7_hwx8-dFzmK {
      font-size: 0.875rem;
      padding-left: 0.7rem;
      padding-right: 0.7rem; }
      ._1ycxtTMNI8xVlG0NvqUSWf ._2hVg5bJ1yCPlS0uU1YMTVL .xoZzjubeO7_hwx8-dFzmK a {
        color: black; }
  ._1ycxtTMNI8xVlG0NvqUSWf ._2lxXXPefvHji92dKEq8Ip- {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column; }
    ._1ycxtTMNI8xVlG0NvqUSWf ._2lxXXPefvHji92dKEq8Ip- ._1KkfAtwSf5_vRgaBIo4Jd6 {
      font-size: 1.5625rem;
      line-height: 2.4375rem;
      font-weight: bold; }
    ._1ycxtTMNI8xVlG0NvqUSWf ._2lxXXPefvHji92dKEq8Ip- ._2K9lEsjtHPqRohgnjFHYyG {
      width: 143px;
      border: 0.5px solid #0074b3; }
    ._1ycxtTMNI8xVlG0NvqUSWf ._2lxXXPefvHji92dKEq8Ip- ._1vrfWwBno2mgbM84PQWCi {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      ._1ycxtTMNI8xVlG0NvqUSWf ._2lxXXPefvHji92dKEq8Ip- ._1vrfWwBno2mgbM84PQWCi .GE_xdDJdC8H3gDOPGIO5- {
        cursor: pointer;
        margin: 10px; }
        ._1ycxtTMNI8xVlG0NvqUSWf ._2lxXXPefvHji92dKEq8Ip- ._1vrfWwBno2mgbM84PQWCi .GE_xdDJdC8H3gDOPGIO5- ._3bHoM99Z8NBh2vUBM3zM7q {
          height: 400px; }
        ._1ycxtTMNI8xVlG0NvqUSWf ._2lxXXPefvHji92dKEq8Ip- ._1vrfWwBno2mgbM84PQWCi .GE_xdDJdC8H3gDOPGIO5- ._3waLGowc0_40D7PzdqrZKv {
          margin-top: -5px;
          font-size: 1.125rem; }

@media (max-width: 767px) {
  ._1ycxtTMNI8xVlG0NvqUSWf ._2lxXXPefvHji92dKEq8Ip- ._1vrfWwBno2mgbM84PQWCi .GE_xdDJdC8H3gDOPGIO5- ._3bHoM99Z8NBh2vUBM3zM7q {
    height: 300px; } }

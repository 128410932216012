._1ykh8bcU-H6ymYnEUJRWFA {
  display: block;
  border-bottom: 1px solid black;
  position: relative; }
  ._1ykh8bcU-H6ymYnEUJRWFA ._3UnOVUkB-cByTqWuzp_rb_ {
    color: black;
    position: absolute;
    top: 24%;
    left: 20%; }
    ._1ykh8bcU-H6ymYnEUJRWFA ._3UnOVUkB-cByTqWuzp_rb_ a {
      text-decoration: none; }
    ._1ykh8bcU-H6ymYnEUJRWFA ._3UnOVUkB-cByTqWuzp_rb_ .Wj58MEkn890YATeHY61x9 {
      color: black;
      display: block; }
    ._1ykh8bcU-H6ymYnEUJRWFA ._3UnOVUkB-cByTqWuzp_rb_ ._2FrGIXtfOQydAyaYFLNpbl {
      display: none; }
  ._1ykh8bcU-H6ymYnEUJRWFA ._29niYt8UP90yYXCqHfPQkK {
    display: flex;
    justify-content: center; }

._2bsMxOVnvw81Vy1e1GSfoI {
  width: 6px;
  margin-right: 5px; }

@media (max-width: 1024px) {
  ._1ykh8bcU-H6ymYnEUJRWFA ._3UnOVUkB-cByTqWuzp_rb_ .Wj58MEkn890YATeHY61x9 {
    display: none; }
  ._1ykh8bcU-H6ymYnEUJRWFA ._3UnOVUkB-cByTqWuzp_rb_ ._2FrGIXtfOQydAyaYFLNpbl {
    color: black;
    display: block;
    margin-left: 5px; } }

@media (max-width: 500px) {
  ._1ykh8bcU-H6ymYnEUJRWFA ._3UnOVUkB-cByTqWuzp_rb_ {
    left: 0; } }

._24gLsEDJj_PPpT-mHUdAjd ._2o79Cfd6WGTfPNniPLXXAn {
  margin: 0 auto 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 15px; }

._24gLsEDJj_PPpT-mHUdAjd ._10wkj4jgTkA1e8_1jIWKB {
  padding: 0 !important; }

._24gLsEDJj_PPpT-mHUdAjd ._3BWArEVKbNSG6QwMR6-zMf {
  margin: 10px;
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 3px 0px; }
  ._24gLsEDJj_PPpT-mHUdAjd ._3BWArEVKbNSG6QwMR6-zMf img {
    width: 100%;
    height: auto; }

._24gLsEDJj_PPpT-mHUdAjd ._1SLqetGE3MHaBEGqa0Bf4x {
  text-transform: uppercase; }

._24gLsEDJj_PPpT-mHUdAjd ._3IiNpSSbbRJeqcdZQL5GZb {
  padding: 20px; }

._24gLsEDJj_PPpT-mHUdAjd ._2c7Bjfx8d64Qo7Enj-ZzUI {
  border-bottom: 1px solid #eee; }

._24gLsEDJj_PPpT-mHUdAjd ._3GodjcYqfYX3xrh86nMg1o {
  width: 100%;
  min-height: 200px;
  background-size: cover; }

._24gLsEDJj_PPpT-mHUdAjd ._3qCfSfWvJXO7b1z0VUHVLG {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px; }

._2OvZHIIOobazgvzluDFuC2 {
  background-size: cover;
  height: 250px;
  background-repeat: no-repeat; }

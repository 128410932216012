._2Q7ukJgQHxdL3iQPSF2N8v {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  background-color: white;
  color: black;
  opacity: 0;
  box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.25);
  transition: all 0.25s; }
  ._2Q7ukJgQHxdL3iQPSF2N8v .ejWNqZju7xOodBMNSnWEO {
    position: absolute;
    width: 100vw;
    top: 40px;
    left: 0;
    max-height: calc(100vh - 40px);
    transition: transform 0.25s; }
  ._2Q7ukJgQHxdL3iQPSF2N8v ._1jkpiMmd98VRMrbfQ27V5Q {
    transform: translate(0);
    height: 100%; }
  ._2Q7ukJgQHxdL3iQPSF2N8v ._2OiLFnonbJFus9VwxVqESJ {
    padding: 20px 30px;
    position: relative; }
    ._2Q7ukJgQHxdL3iQPSF2N8v ._2OiLFnonbJFus9VwxVqESJ a {
      background-color: #007ea8;
      padding: 0.5rem 1rem;
      color: white;
      text-decoration: none;
      font-size: 0.9rem;
      font-weight: bold; }
  ._2Q7ukJgQHxdL3iQPSF2N8v .pqXkrNEBNTU17JkbNH4dj {
    outline: none;
    border: none;
    background: transparent;
    font-size: 25px;
    margin: 12px;
    color: black; }
  ._2Q7ukJgQHxdL3iQPSF2N8v ._1SEa6WVsSGUHojDLD1waqS {
    padding-bottom: 0.9rem;
    font-size: 0.9rem; }
  ._2Q7ukJgQHxdL3iQPSF2N8v .WMCLB8U5jgCDI5fPj4m5a {
    padding-bottom: 0.1rem;
    font-size: 0.9rem;
    text-decoration: underline; }
  ._2Q7ukJgQHxdL3iQPSF2N8v ._2-cRs2kp5DnsgOeqgMilLP {
    padding-bottom: 0.1rem;
    font-size: 0.9rem; }
  ._2Q7ukJgQHxdL3iQPSF2N8v ._3A20_sGAM1cysJbcyrGVQc {
    padding-top: 0.9rem;
    padding-bottom: 1rem;
    font-size: 0.9rem;
    font-weight: bold; }
  ._2Q7ukJgQHxdL3iQPSF2N8v .QfYmylFpWjgTQgqbLZVn8 {
    font-size: 0.9rem;
    padding-bottom: 1rem;
    font-weight: bold; }
  ._2Q7ukJgQHxdL3iQPSF2N8v ._38mlCj4HRRIJQlU2koPxSv {
    font-size: 0.9rem;
    padding-top: 1rem;
    padding-bottom: 0.9rem;
    font-weight: bold; }
  ._2Q7ukJgQHxdL3iQPSF2N8v ._1U7c6SFgW9dwOdS1JGlDtl {
    margin-left: 0;
    width: 160px;
    text-align: center; }
  ._2Q7ukJgQHxdL3iQPSF2N8v ._3Itdjleh0QlTzp9AqbioYN {
    display: flex;
    width: 100%; }
  ._2Q7ukJgQHxdL3iQPSF2N8v ._30WK-JP-lTujJfedEfT_RX {
    background-color: transparent;
    color: #1387b0;
    text-decoration: underline;
    font-size: 0.8rem;
    padding: 0;
    padding-top: 0.5rem; }
  ._2Q7ukJgQHxdL3iQPSF2N8v ._2vlBdbhbhX8--RCUtOUUQN {
    background-color: #f0f0f0;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 10px 20px 50px 20px;
    display: flex;
    flex-direction: column; }
    ._2Q7ukJgQHxdL3iQPSF2N8v ._2vlBdbhbhX8--RCUtOUUQN a {
      color: black;
      text-decoration: none;
      font-weight: bold; }

._2E5eN05ca2DtHNhStFcYKE {
  border-bottom: 1px solid #d5d5d5;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem; }
  ._2E5eN05ca2DtHNhStFcYKE .djUYoEJ5OepUEitShWSSb {
    display: flex; }
  ._2E5eN05ca2DtHNhStFcYKE ._2YprUL9SXhavfaNcGaFi7l {
    color: black;
    text-align: center;
    font-size: 0.9375rem;
    background-color: white; }
  ._2E5eN05ca2DtHNhStFcYKE ._1ZLy-DqppMdiAAAtZJUE2a {
    position: relative; }
  ._2E5eN05ca2DtHNhStFcYKE ._2qeGviGS9wBs5jdmQmMn-d {
    position: absolute;
    left: 17px;
    top: -7px;
    background: orange;
    color: black !important;
    border-radius: 20px;
    width: 17px;
    height: 17px;
    text-align: center;
    font-size: 0.7rem;
    font-weight: 700;
    padding-top: 1px;
    padding-right: 1px; }
  ._2E5eN05ca2DtHNhStFcYKE ._3MJq6ud36sIuqKVw2_XaUH {
    padding-right: 20px; }

._17XMgic2AUxnhjVUQvOAQS {
  transform: translate(100%);
  opacity: 1; }

@keyframes _1n5xcgtVgfdCT-Un6X99ve {
  0% {
    transform: translateX(0) translateY(0); }
  100% {
    transform: translateX(-100vw) translateY(0); } }

@keyframes ATL4hSjGNprau3x_XYisv {
  0% {
    transform: translateX(0) translateY(0); }
  100% {
    transform: translateX(100vw) translateY(0); } }

@keyframes _1V_REN6CNjXvgqL3sz0bGB {
  0% {
    transform: translateX(-100vw) translateY(0);
    opacity: 1; }
  100% {
    transform: translateX(0) translateY(0); } }

@keyframes _3Jf3jejPgfoR_wxHSt3eSi {
  0% {
    transform: translateX(100vw) translateY(0);
    opacity: 1; }
  100% {
    transform: translateX(0) translateY(0); } }
